const ZarIcon = ({
  width = "100%",
  height = "100%",
  className = "",
}: {
  width?: string;
  height?: string;
  className?: string;
}) => {
  return (
    <svg
      className={className + " rounded-full fill-current"}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
    >
      <path d="m21.387 23.274-3.223-5.885c.148-.052.294-.112.436-.18a3.797 3.797 0 0 0 1.676-1.573c.383-.722.575-1.53.558-2.347a4.912 4.912 0 0 0-.554-2.369 3.88 3.88 0 0 0-1.673-1.608 6.05 6.05 0 0 0-2.81-.583h-5.184v14.546h2.195V17.77h2.982c.049 0 .092-.005.14-.006l2.957 5.51h2.5Zm-8.579-12.663h2.755a4.074 4.074 0 0 1 1.758.323c.422.192.77.517.987.927.22.443.329.933.317 1.428.013.485-.094.966-.313 1.4-.219.397-.565.71-.983.887a4.221 4.221 0 0 1-1.737.305h-2.784v-5.27Z" />
    </svg>
  );
};

export default ZarIcon;
