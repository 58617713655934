const CzkIcon = ({
  width = "100%",
  height = "100%",
  className = "",
}: {
  width?: string;
  height?: string;
  className?: string;
}) => {
  return (
    <svg
      className={className + " rounded-full fill-current"}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
    >
      <path d="M5.3 23.557V9.012h2.196v6.945h.176l6.102-6.945h2.763L10.67 15.56l5.888 7.997h-2.643l-4.7-6.498-1.72 1.974v4.524H5.3Zm16.918.22a4.99 4.99 0 0 1-2.723-.72 4.724 4.724 0 0 1-1.751-1.992 6.646 6.646 0 0 1-.61-2.913 6.587 6.587 0 0 1 .624-2.937 4.797 4.797 0 0 1 1.762-1.992 4.907 4.907 0 0 1 2.677-.717 5.15 5.15 0 0 1 2.216.458 4.026 4.026 0 0 1 1.573 1.282c.406.566.653 1.23.714 1.925h-2.067a2.487 2.487 0 0 0-.777-1.321 2.318 2.318 0 0 0-1.623-.554 2.635 2.635 0 0 0-1.552.465c-.462.334-.82.792-1.033 1.321a5.058 5.058 0 0 0-.37 2.02 5.326 5.326 0 0 0 .362 2.06c.21.535.565 1 1.026 1.342a2.81 2.81 0 0 0 2.664.253c.324-.145.609-.366.83-.643.234-.296.395-.64.473-1.009H26.7a3.94 3.94 0 0 1-2.227 3.196 5.026 5.026 0 0 1-2.255.476ZM20.72 8.223l1.463 1.904 1.456-1.904h1.925v.107l-2.5 3.011h-1.769l-2.493-3.01v-.108h1.918Z" />
    </svg>
  );
};

export default CzkIcon;
