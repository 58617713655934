const ChfIcon = ({
  width = "100%",
  height = "100%",
  className = "",
}: {
  width?: string;
  height?: string;
  className?: string;
}) => {
  return (
    <svg
      className={className + " rounded-full fill-current"}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
    >
      <path d="M10.403 20.773v-1.47h6.528v1.47h-6.528Zm2.174 2.5V8.727h9.02v1.89H14.77v4.431h6.18v1.882h-6.18v6.343h-2.193Z" />
    </svg>
  );
};

export default ChfIcon;
