const MOBIIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 36 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.57141 0H24.4195L36 12.8665V43.0221C36 44.6067 34.8481 45.8902 33.4286 45.8902H2.57141C1.15194 45.8902 0 44.6067 0 43.0221V2.86811C0 1.28352 1.15207 0 2.57141 0Z"
      fill="#FCD34D"
    />
    <path
      d="M4.76172 29.0586C5.32422 29.0586 5.64648 28.6895 5.64648 28.0508V24.0254H5.69336L7.09375 27.5879C7.32227 28.1738 7.59766 28.373 7.99609 28.373C8.38867 28.373 8.6582 28.1855 8.88672 27.5879L10.2812 24.0254H10.334V28.0508C10.334 28.707 10.627 29.0586 11.1777 29.0586C11.7402 29.0586 12.0625 28.6895 12.0625 28.0508V22.1914C12.0625 21.3945 11.6465 20.9375 10.9199 20.9375C10.2695 20.9375 9.88867 21.2305 9.57227 22.0801L8.02539 26.082H7.96094L6.4082 22.0859C6.09766 21.248 5.69336 20.9375 5.07227 20.9375C4.35742 20.9375 3.91211 21.4121 3.91211 22.1914V28.0508C3.91211 28.707 4.20508 29.0586 4.76172 29.0586ZM13.3281 25.5137C13.3281 27.793 14.6289 29.1758 16.8613 29.1758C19.082 29.1758 20.3887 27.7988 20.3887 25.5137V24.5586C20.3887 22.2559 19.082 20.8555 16.8613 20.8555C14.6406 20.8555 13.3281 22.25 13.3281 24.5586V25.5137ZM15.2676 24.5469C15.2676 23.2227 15.8594 22.4375 16.8613 22.4375C17.8516 22.4375 18.4492 23.2227 18.4492 24.5469V25.5137C18.4492 26.8203 17.8574 27.5938 16.8613 27.5938C15.8594 27.5938 15.2676 26.8203 15.2676 25.5137V24.5469ZM21.666 27.8926C21.666 28.6133 22.0586 29 22.791 29H25.2695C26.9395 29 28.0527 28.0918 28.0527 26.7324C28.0527 25.6543 27.3145 24.8691 26.2422 24.7988V24.7109C27.1211 24.5469 27.6719 23.8789 27.6719 22.9648C27.6719 21.7578 26.7227 21.002 25.2109 21.002H22.791C22.0586 21.002 21.666 21.3945 21.666 22.1094V27.8926ZM23.582 24.2539V22.373H24.7188C25.3926 22.373 25.7793 22.7363 25.7793 23.2988C25.7793 23.8672 25.3984 24.2539 24.5137 24.2539H23.582ZM23.582 27.6289V25.4961H24.8125C25.6562 25.4961 26.084 25.9238 26.084 26.5742C26.084 27.2246 25.6562 27.6289 24.6191 27.6289H23.582ZM30.1562 29.0586C30.7715 29.0586 31.1172 28.6836 31.1172 28.0156V21.9863C31.1172 21.3242 30.7656 20.9434 30.1445 20.9434C29.5234 20.9434 29.1777 21.3184 29.1777 21.9863V28.0156C29.1777 28.6777 29.5352 29.0586 30.1562 29.0586Z"
      fill="white"
    />
    <path
      d="M2.57141 0H24.4195L36 12.8665V43.0221C36 44.6067 34.8481 45.8902 33.4286 45.8902H2.57141C1.15194 45.8902 0 44.6067 0 43.0221V2.86811C0 1.28352 1.15207 0 2.57141 0Z"
      fill="#FCD34D"
    />
    <path
      d="M4.76172 29.0586C5.32422 29.0586 5.64648 28.6895 5.64648 28.0508V24.0254H5.69336L7.09375 27.5879C7.32227 28.1738 7.59766 28.373 7.99609 28.373C8.38867 28.373 8.6582 28.1855 8.88672 27.5879L10.2812 24.0254H10.334V28.0508C10.334 28.707 10.627 29.0586 11.1777 29.0586C11.7402 29.0586 12.0625 28.6895 12.0625 28.0508V22.1914C12.0625 21.3945 11.6465 20.9375 10.9199 20.9375C10.2695 20.9375 9.88867 21.2305 9.57227 22.0801L8.02539 26.082H7.96094L6.4082 22.0859C6.09766 21.248 5.69336 20.9375 5.07227 20.9375C4.35742 20.9375 3.91211 21.4121 3.91211 22.1914V28.0508C3.91211 28.707 4.20508 29.0586 4.76172 29.0586ZM13.3281 25.5137C13.3281 27.793 14.6289 29.1758 16.8613 29.1758C19.082 29.1758 20.3887 27.7988 20.3887 25.5137V24.5586C20.3887 22.2559 19.082 20.8555 16.8613 20.8555C14.6406 20.8555 13.3281 22.25 13.3281 24.5586V25.5137ZM15.2676 24.5469C15.2676 23.2227 15.8594 22.4375 16.8613 22.4375C17.8516 22.4375 18.4492 23.2227 18.4492 24.5469V25.5137C18.4492 26.8203 17.8574 27.5938 16.8613 27.5938C15.8594 27.5938 15.2676 26.8203 15.2676 25.5137V24.5469ZM21.666 27.8926C21.666 28.6133 22.0586 29 22.791 29H25.2695C26.9395 29 28.0527 28.0918 28.0527 26.7324C28.0527 25.6543 27.3145 24.8691 26.2422 24.7988V24.7109C27.1211 24.5469 27.6719 23.8789 27.6719 22.9648C27.6719 21.7578 26.7227 21.002 25.2109 21.002H22.791C22.0586 21.002 21.666 21.3945 21.666 22.1094V27.8926ZM23.582 24.2539V22.373H24.7188C25.3926 22.373 25.7793 22.7363 25.7793 23.2988C25.7793 23.8672 25.3984 24.2539 24.5137 24.2539H23.582ZM23.582 27.6289V25.4961H24.8125C25.6562 25.4961 26.084 25.9238 26.084 26.5742C26.084 27.2246 25.6562 27.6289 24.6191 27.6289H23.582ZM30.1562 29.0586C30.7715 29.0586 31.1172 28.6836 31.1172 28.0156V21.9863C31.1172 21.3242 30.7656 20.9434 30.1445 20.9434C29.5234 20.9434 29.1777 21.3184 29.1777 21.9863V28.0156C29.1777 28.6777 29.5352 29.0586 30.1562 29.0586Z"
      fill="white"
    />
    <path
      d="M35.958 12.9116H26.9952C25.5758 12.9116 24.4238 11.6268 24.4238 10.0435V0.0336914L35.958 12.9116Z"
      fill="#F59E0B"
    />
  </svg>
);

export default MOBIIcon;
