const HrkIcon = ({
  width = "100%",
  height = "100%",
  className = "",
}: {
  width?: string;
  height?: string;
  className?: string;
}) => {
  return (
    <svg
      className={className + " rounded-full fill-current"}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
    >
      <path d="M15.51 13.716h-2.26l-2.27 2.69h-.1v-5.05H8.91v9.46h1.97v-2.26l.53-.61 1.93 2.87h2.31l-2.77-4.03 2.63-3.07Zm7.2 1.16a2.21 2.21 0 0 0-.86-.93 2.51 2.51 0 0 0-1.28-.32 2.35 2.35 0 0 0-1.35.37 2 2 0 0 0-.79 1h-.08v-1.28h-1.87v7.09h2v-4.09a1.879 1.879 0 0 1 .17-.79 1.23 1.23 0 0 1 .47-.49 1.38 1.38 0 0 1 .71-.17 1.19 1.19 0 0 1 .92.36c.235.278.356.635.34 1v4.18h2v-4.51a3.1 3.1 0 0 0-.38-1.42Z" />
    </svg>
  );
};

export default HrkIcon;
