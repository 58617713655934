const TryIcon = ({
  width = "100%",
  height = "100%",
  className = "",
}: {
  width?: string;
  height?: string;
  className?: string;
}) => {
  return (
    <svg
      className={className + " rounded-full fill-current"}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
    >
      <path d="M17.89 9.75v1.882l-6.904 2.805v-1.875l6.903-2.812Zm0 3.303v1.867l-6.904 2.806V15.85l6.903-2.799Zm-2.785-4.425v14.545l-2.188.078V8.628h2.188Zm3.7 8.04h2.21a8.26 8.26 0 0 1-.747 3.718 5.107 5.107 0 0 1-2.081 2.237 6.264 6.264 0 0 1-3.117.75c-.407 0-.805-.011-1.194-.033a10.563 10.563 0 0 1-.959-.089l2.153-1.81a3.93 3.93 0 0 0 1.917-.463 3.159 3.159 0 0 0 1.332-1.509 6.74 6.74 0 0 0 .486-2.802Z" />
    </svg>
  );
};

export default TryIcon;
