const DopIcon = ({
  width = "100%",
  height = "100%",
  className = "",
}: {
  width?: string;
  height?: string;
  className?: string;
}) => {
  const regex = /\btext-([a-zA-Z0-9-]+)\b/;
  const matches = className.match(regex);
  const textClass = matches ? matches[0] : "text-white";
  return (
    <svg
      className={className + " rounded-full fill-current"}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
    >
      <g
        transform="translate(6,26.000000) scale(0.004000, -0.004000)"
        fill={textClass}
        stroke="none"
      >
        <path
          d="M4338 4105 c-19 -13 -41 -39 -47 -56 -6 -19 -11 -106 -11 -214 l0
-182 -42 -7 c-130 -20 -286 -106 -360 -200 -94 -117 -133 -255 -124 -431 9
-176 41 -263 131 -351 58 -56 189 -126 315 -168 l75 -25 3 -360 c2 -286 0
-361 -10 -361 -24 0 -133 58 -161 85 -65 62 -99 147 -112 274 -3 35 -13 77
-21 93 -47 91 -199 89 -247 -3 -30 -58 -6 -244 50 -379 72 -176 225 -298 423
-337 l80 -16 0 -185 c0 -169 2 -187 21 -222 26 -45 65 -70 114 -70 48 0 91 24
115 63 18 29 20 51 20 225 l0 192 24 0 c33 0 151 37 206 65 223 113 340 318
340 593 0 318 -117 462 -473 584 l-97 33 0 317 0 317 44 -15 c58 -20 129 -83
155 -137 11 -23 25 -73 31 -112 15 -96 34 -130 87 -156 52 -25 101 -20 150 17
46 35 56 76 43 173 -38 278 -173 432 -427 487 l-83 17 0 191 c0 210 -4 226
-59 263 -46 31 -105 31 -153 -2z m-58 -1000 c0 -151 -1 -275 -3 -275 -17 0
-135 55 -170 78 -57 40 -72 77 -72 177 1 135 52 216 169 271 28 13 57 24 64
24 9 0 12 -62 12 -275z m396 -776 c134 -64 170 -125 162 -269 -8 -132 -67
-229 -171 -279 -34 -16 -74 -32 -89 -35 l-28 -6 0 320 0 321 23 -6 c12 -3 59
-24 103 -46z"
        />
        <path
          d="M13 3679 c-10 -10 -13 -253 -13 -1146 l0 -1133 26 -10 c14 -6 90 -10
170 -10 131 0 146 2 164 20 19 19 20 33 20 475 l0 455 212 -2 211 -3 52 -140
c28 -77 102 -282 164 -455 72 -200 121 -322 134 -333 19 -16 38 -18 184 -15
172 3 182 6 183 51 0 10 -81 235 -180 499 -99 264 -180 485 -180 492 0 6 27
38 61 71 85 84 153 190 185 288 36 110 45 307 19 422 -52 236 -230 395 -516
460 -99 23 -876 35 -896 14z m704 -313 c157 -24 266 -93 314 -199 20 -43 24
-69 24 -152 0 -120 -19 -169 -92 -243 -95 -94 -190 -122 -420 -122 l-163 0 0
365 0 365 123 0 c67 0 163 -6 214 -14z"
        />
        <path
          d="M2110 3670 c-105 -9 -240 -26 -292 -36 l-28 -6 0 -1093 0 -1093 43
-6 c96 -14 303 -26 467 -26 408 0 643 73 863 267 257 227 378 523 378 923 0
332 -90 576 -281 770 -149 151 -275 225 -470 275 -97 25 -123 27 -340 30 -129
2 -282 0 -340 -5z m472 -300 c74 -11 177 -43 220 -70 53 -32 179 -145 218
-195 119 -154 170 -399 141 -685 -15 -154 -42 -258 -93 -360 -81 -162 -226
-272 -418 -315 -84 -19 -345 -32 -434 -21 l-56 7 0 814 0 813 63 6 c34 3 76 8
92 9 64 8 203 6 267 -3z"
        />
      </g>
    </svg>
  );
};

export default DopIcon;
