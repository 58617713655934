const DOCIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 36 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.57141 0H24.4195L36 12.8665V43.0221C36 44.6067 34.8481 45.8902 33.4286 45.8902H2.57141C1.15194 45.8902 0 44.6067 0 43.0221V2.86811C0 1.28352 1.15207 0 2.57141 0Z"
      fill="#3B82F6"
    />
    <path
      d="M5.98438 27.8926C5.98438 28.6133 6.38281 29 7.11523 29H9.125C11.4805 29 12.7402 27.6055 12.7402 24.998C12.7402 22.3965 11.4805 21.002 9.125 21.002H7.11523C6.38281 21.002 5.98438 21.3887 5.98438 22.1094V27.8926ZM7.92383 27.459V22.5371H8.82617C10.1562 22.5371 10.7598 23.3047 10.7598 24.998C10.7598 26.6973 10.1562 27.459 8.82617 27.459H7.92383ZM13.6191 25.5137C13.6191 27.793 14.9199 29.1758 17.1523 29.1758C19.373 29.1758 20.6797 27.7988 20.6797 25.5137V24.5586C20.6797 22.2559 19.373 20.8555 17.1523 20.8555C14.9316 20.8555 13.6191 22.25 13.6191 24.5586V25.5137ZM15.5586 24.5469C15.5586 23.2227 16.1504 22.4375 17.1523 22.4375C18.1426 22.4375 18.7402 23.2227 18.7402 24.5469V25.5137C18.7402 26.8203 18.1484 27.5938 17.1523 27.5938C16.1504 27.5938 15.5586 26.8203 15.5586 25.5137V24.5469ZM21.6699 25.4023C21.6699 27.8047 22.9062 29.1758 25.1445 29.1758C26.1113 29.1758 26.9199 28.9121 27.4941 28.4668C28.0039 28.0859 28.3203 27.5703 28.3203 27.1074C28.3203 26.6445 27.998 26.3281 27.5234 26.3281C27.2246 26.3281 27.0137 26.4395 26.7031 26.7617C26.2871 27.2891 25.8301 27.5352 25.2617 27.5352C24.1953 27.5352 23.6094 26.7559 23.6094 25.3145V24.623C23.6094 23.2695 24.207 22.4902 25.2617 22.4902C25.8242 22.4902 26.2344 22.7363 26.6738 23.2754C26.9785 23.5859 27.207 23.7031 27.5176 23.7031C27.9863 23.7031 28.3086 23.3926 28.3086 22.9238C28.3086 22.5605 28.0742 22.1035 27.6875 21.7285C27.1133 21.1719 26.1875 20.8555 25.1445 20.8555C22.9414 20.8555 21.6699 22.2324 21.6699 24.5352V25.4023Z"
      fill="white"
    />
    <path
      d="M35.958 12.9116H26.9952C25.5758 12.9116 24.4238 11.6268 24.4238 10.0435V0.0336914L35.958 12.9116Z"
      fill="#1E40AF"
    />
  </svg>
);

export default DOCIcon;
