const PPTIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 36 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.57141 0H24.4195L36 12.8665V43.0221C36 44.6067 34.8481 45.8902 33.4286 45.8902H2.57141C1.15194 45.8902 0 44.6067 0 43.0221V2.86811C0 1.28352 1.15207 0 2.57141 0Z"
      fill="#E2574C"
    />
    <path
      d="M8.79883 29.0586C9.41992 29.0586 9.77148 28.6777 9.77148 28.0156V26.4453H11.0605C12.8945 26.4453 14.0195 25.4668 14.0195 23.7148C14.0195 22.0684 12.9121 21.002 11.1602 21.002H9.10352C8.18945 21.002 7.83789 21.3711 7.83789 22.3262V28.0156C7.83789 28.6836 8.18359 29.0586 8.79883 29.0586ZM9.77734 25.0039V22.4961H10.873C11.6641 22.4961 12.1035 22.9531 12.1035 23.75C12.1035 24.6758 11.6582 25.0039 10.6562 25.0039H9.77734ZM15.9941 29.0586C16.6152 29.0586 16.9668 28.6777 16.9668 28.0156V26.4453H18.2559C20.0898 26.4453 21.2148 25.4668 21.2148 23.7148C21.2148 22.0684 20.1074 21.002 18.3555 21.002H16.2988C15.3848 21.002 15.0332 21.3711 15.0332 22.3262V28.0156C15.0332 28.6836 15.3789 29.0586 15.9941 29.0586ZM16.9727 25.0039V22.4961H18.0684C18.8594 22.4961 19.2988 22.9531 19.2988 23.75C19.2988 24.6758 18.8535 25.0039 17.8516 25.0039H16.9727ZM24.7188 29.0586C25.3223 29.0586 25.6914 28.6719 25.6914 28.0391V22.5898H27.2207C27.666 22.5898 27.9941 22.25 27.9941 21.7988C27.9941 21.3418 27.666 21.002 27.2207 21.002H22.2344C21.7891 21.002 21.4551 21.3418 21.4551 21.7988C21.4551 22.25 21.7891 22.5898 22.2344 22.5898H23.752V28.0391C23.752 28.6719 24.1152 29.0586 24.7188 29.0586Z"
      fill="white"
    />
    <path
      d="M35.958 12.9116H26.9952C25.5758 12.9116 24.4238 11.6268 24.4238 10.0435V0.0336914L35.958 12.9116Z"
      fill="#B53629"
    />
  </svg>
);

export default PPTIcon;
