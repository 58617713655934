const HufIcon = ({
  width = "100%",
  height = "100%",
  className = "",
}: {
  width?: string;
  height?: string;
  className?: string;
}) => {
  return (
    <svg
      className={className + " rounded-full fill-current"}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
    >
      <path d="M10.266 11.223h6.26v1.647h-4.26v2.254h3.845v1.647h-3.846v3.906h-1.999v-9.454Zm11.158 7.903-.212.04a1.582 1.582 0 0 1-.277.025.941.941 0 0 1-.349-.06.454.454 0 0 1-.232-.21.881.881 0 0 1-.083-.423v-3.435h1.334v-1.477h-1.334v-1.698h-1.967v1.698h-.97v1.477h.97v3.694a2.06 2.06 0 0 0 .291 1.154c.198.306.488.542.828.674.397.15.821.215 1.245.19.219-.005.437-.031.65-.077.179-.042.317-.078.416-.109l-.31-1.463Z" />
    </svg>
  );
};

export default HufIcon;
