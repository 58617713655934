const WAVIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 36 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.57141 0H24.4195L36 12.8665V43.0221C36 44.6067 34.8481 45.8902 33.4286 45.8902H2.57141C1.15194 45.8902 0 44.6067 0 43.0221V2.86811C0 1.28352 1.15207 0 2.57141 0Z"
      fill="#A78BFA"
    />
    <path
      d="M8.04688 28.0684C8.25781 28.8066 8.63867 29.0469 9.21875 29.0469C9.79297 29.0469 10.1914 28.707 10.3613 28.0684L11.4629 23.8906H11.5391L12.6289 28.0156C12.8164 28.7422 13.209 29.0469 13.8242 29.0469C14.4102 29.0469 14.7617 28.7422 14.9492 28.0684L16.5137 22.3906C16.5547 22.25 16.584 21.998 16.584 21.834C16.584 21.3066 16.1973 20.9316 15.6465 20.9316C15.125 20.9316 14.7969 21.2773 14.6504 21.9922L13.6953 26.4219H13.5957L12.5234 21.9629C12.3477 21.248 12.043 20.9316 11.498 20.9316C10.9531 20.9316 10.6602 21.2363 10.4844 21.9629L9.40625 26.4219H9.3125L8.31641 21.9922C8.15234 21.2656 7.84766 20.9316 7.33203 20.9316C6.76953 20.9316 6.38281 21.3066 6.38281 21.8516C6.38281 22.0098 6.41797 22.2676 6.48242 22.5078L8.04688 28.0684ZM17.4453 29.0645C17.9609 29.0645 18.2363 28.8008 18.4297 28.1211L18.7402 27.166H21.4297L21.7344 28.1211C21.9277 28.7832 22.2266 29.0645 22.7422 29.0645C23.2988 29.0645 23.6621 28.7363 23.6621 28.2266C23.6621 28.0215 23.6094 27.7871 23.5039 27.459L21.7402 22.2734C21.418 21.3184 20.9727 20.9434 20.1289 20.9434C19.2852 20.9434 18.8281 21.3242 18.5117 22.2734L16.7422 27.459C16.6074 27.8516 16.5547 28.0742 16.5547 28.2676C16.5547 28.7363 16.918 29.0645 17.4453 29.0645ZM19.1387 25.7656L20.0469 22.7363H20.1406L21.0371 25.7656H19.1387ZM27.0898 26.9258H27.002L25.4727 21.8867C25.2793 21.2422 24.957 20.9316 24.459 20.9316C23.9023 20.9316 23.5039 21.2891 23.5039 21.8047C23.5039 21.9922 23.5566 22.2617 23.6562 22.5547L25.3848 27.6934C25.6895 28.6484 26.1758 29.0586 27.0254 29.0586C27.8633 29.0586 28.332 28.6543 28.6426 27.6934L30.3711 22.5547C30.459 22.2793 30.5234 21.9805 30.5234 21.7871C30.5234 21.2773 30.1309 20.9316 29.5742 20.9316C29.0586 20.9316 28.7715 21.207 28.584 21.8867L27.0898 26.9258Z"
      fill="white"
    />
    <path
      d="M35.958 12.9116H26.9952C25.5758 12.9116 24.4238 11.6268 24.4238 10.0435V0.0336914L35.958 12.9116Z"
      fill="#7C3AED"
    />
  </svg>
);

export default WAVIcon;
