const ClpIcon = ({
  width = "100%",
  height = "100%",
  className = "",
}: {
  width?: string;
  height?: string;
  className?: string;
}) => {
  return (
    <svg
      className={className + " rounded-full fill-current"}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
    >
      <path d="M19.1 12.54a2.09 2.09 0 0 0-.94-1.56 3.64 3.64 0 0 0-2.08-.56 3.818 3.818 0 0 0-1.54.28 2.29 2.29 0 0 0-1 .77 1.79 1.79 0 0 0-.36 1.1c-.01.317.074.63.24.9.167.256.39.47.65.63a5.7 5.7 0 0 0 .86.42c.292.106.589.196.89.27l1.42.37c.486.121.964.275 1.43.46.484.191.939.45 1.35.77.414.317.755.72 1 1.18a3.5 3.5 0 0 1 .38 1.68 3.88 3.88 0 0 1-.63 2.19 4.36 4.36 0 0 1-1.84 1.52 6.933 6.933 0 0 1-2.91.55 7 7 0 0 1-2.83-.52 4.31 4.31 0 0 1-1.87-1.47 4.26 4.26 0 0 1-.74-2.27h2.2c.022.48.201.941.51 1.31.311.355.712.621 1.16.77a4.54 4.54 0 0 0 1.55.26 4.312 4.312 0 0 0 1.66-.3 2.68 2.68 0 0 0 1.15-.82c.28-.352.428-.79.42-1.24a1.51 1.51 0 0 0-.37-1.06 2.798 2.798 0 0 0-1-.68c-.458-.193-.93-.353-1.41-.48l-1.72-.47a6.531 6.531 0 0 1-2.76-1.4 3.13 3.13 0 0 1-1-2.44 3.63 3.63 0 0 1 .68-2.19 4.42 4.42 0 0 1 1.85-1.46 6.29 6.29 0 0 1 2.62-.52 6.35 6.35 0 0 1 2.62.51 4.38 4.38 0 0 1 1.78 1.43 3.56 3.56 0 0 1 .68 2.07h-2.1Zm-3.75 12.55.05-18.18h1.34l-.06 18.18h-1.33Z" />
    </svg>
  );
};

export default ClpIcon;
