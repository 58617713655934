const PygIcon = ({
  width = "100%",
  height = "100%",
  className = "",
}: {
  width?: string;
  height?: string;
  className?: string;
}) => {
  const regex = /\btext-([a-zA-Z0-9-]+)\b/;
  const matches = className.match(regex);
  const textClass = matches ? matches[0] : "text-white";
  return (
    <svg
      className={className + " rounded-full fill-current"}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
    >
      <g
        transform="translate(9,26.000000) scale(0.002000, -0.002000)"
        fill={textClass}
        stroke="none"
      >
        <path
          d="M4296 10083 c-7 -16 -50 -138 -95 -271 -65 -195 -390 -1129 -396
-1139 -1 -2 -125 6 -276 18 -325 25 -703 22 -879 -6 -456 -71 -903 -248 -1287
-506 -683 -461 -1175 -1283 -1302 -2174 -52 -366 -56 -441 -56 -1120 0 -668 4
-753 51 -1025 56 -325 218 -788 375 -1075 202 -367 426 -620 717 -812 79 -52
178 -123 219 -156 68 -56 74 -64 70 -92 -3 -16 -66 -193 -141 -392 -178 -476
-381 -1063 -370 -1072 18 -15 516 -143 524 -135 7 8 152 430 380 1106 46 136
86 252 90 258 5 8 15 8 35 0 40 -15 646 -138 800 -162 261 -40 769 -35 1145
13 486 61 788 155 1433 446 l277 126 0 1118 0 1119 165 0 c242 0 307 20 353
110 31 61 32 218 2 267 -67 111 -217 121 -1780 118 -972 -2 -1047 -5 -1170
-43 -104 -32 -150 -118 -137 -251 11 -105 68 -148 241 -183 32 -7 384 -12 939
-15 l887 -4 0 -963 0 -964 -212 -90 c-441 -188 -726 -270 -1128 -323 -204 -28
-548 -30 -768 -5 -404 44 -813 138 -901 205 l-24 19 17 61 c54 194 693 2133
1136 3451 561 1668 834 2449 872 2497 14 17 107 -20 284 -111 326 -168 517
-334 628 -546 44 -83 70 -167 96 -309 37 -197 71 -258 158 -281 51 -13 194 3
230 27 61 38 77 144 92 578 20 604 11 915 -30 1049 -36 116 -89 156 -207 156
-95 0 -135 -14 -176 -60 -46 -50 -57 -95 -66 -263 -5 -76 -13 -143 -18 -148
-13 -13 -28 -6 -250 128 -154 93 -313 175 -465 241 -33 14 -48 27 -48 40 0 51
107 401 311 1018 71 216 129 403 129 417 0 17 -7 27 -22 31 -137 37 -409 106
-422 106 -8 0 -22 -12 -30 -27z m-896 -1883 c64 -6 138 -16 165 -23 49 -12 50
-13 47 -47 -3 -44 -152 -488 -452 -1355 -127 -368 -365 -1055 -527 -1525 -534
-1548 -843 -2418 -1004 -2827 l-60 -152 -79 57 c-494 363 -793 868 -914 1547
-59 325 -82 738 -72 1255 12 631 59 949 202 1361 204 589 522 1019 979 1324
196 130 487 257 735 320 248 62 681 91 980 65z"
        />
      </g>
    </svg>
  );
};

export default PygIcon;
