import {
  PDFIcon,
  PPTXIcon,
  PPTIcon,
  KEYIcon,
  DOCIcon,
  DOCXIcon,
  PSDIcon,
  AIIcon,
  XLSIcon,
  XLSXIcon,
  EPUBIcon,
  MOBIIcon,
  TXTIcon,
  RTFIcon,
  CSVIcon,
  MP3Icon,
  WAVIcon,
} from "components/Icons";

export const video = ["mp4", "mov", "quicktime", "webm"];
export const image = ["jpg", "jpeg", "png", "svg", "gif", "webp"];
export const document = [
  "ai",
  "csv",
  "doc",
  "docx",
  "epub",
  "key",
  "mobi",
  "mp3",
  "pdf",
  "ppt",
  "pptx",
  "rtf",
  "txt",
  "wav",
  "xls",
  "xlsx",
];

export const documentsWithIcon = [
  { type: "ai", icon: <AIIcon />, color: "#E2574C" },
  { type: "csv", icon: <CSVIcon />, color: "#9CA3AF" },
  { type: "doc", icon: <DOCIcon />, color: "#3B82F6" },
  { type: "docx", icon: <DOCXIcon />, color: "#3B82F6" },
  { type: "epub", icon: <EPUBIcon />, color: "#FCD34D" },
  { type: "key", icon: <KEYIcon />, color: "#E2574C" },
  { type: "mobi", icon: <MOBIIcon />, color: "#FCD34D" },
  { type: "mp3", icon: <MP3Icon />, color: "#A989F3" },
  { type: "pdf", icon: <PDFIcon />, color: "#E2574C" },
  { type: "ppt", icon: <PPTIcon />, color: "#E2574C" },
  { type: "pptx", icon: <PPTXIcon />, color: "#E2574C" },
  { type: "psd", icon: <PSDIcon />, color: "#E2574C" },
  { type: "rtf", icon: <RTFIcon />, color: "#9CA3AF" },
  { type: "txt", icon: <TXTIcon />, color: "#9CA3AF" },
  { type: "wav", icon: <WAVIcon />, color: "#A989F3" },
  { type: "xls", icon: <XLSIcon />, color: "#34D399" },
  { type: "xlsx", icon: <XLSXIcon />, color: "#34D399" },
];
