const FondyIcon = ({
  width = "120px",
  height = "25px",
}: {
  width?: string;
  height?: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 137 31"
  >
    <title>fondy_logo</title>
    <defs>
      <path id="a" d="M0 30.754h30.11V.218H0v30.536z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#3D3D3D"
        d="M51.4 7.819h-7.607v5.311h7.337v3.136h-7.337v10.525h-3.294V4.682h10.9z"
      />
      <path
        d="M57.479 15.672c0 2.442.81 4.45 2.432 6.023 1.611 1.574 3.473 2.36 5.584 2.36 2.29 0 4.222-.8 5.797-2.402 1.574-1.622 2.361-3.597 2.361-5.923 0-2.356-.778-4.33-2.333-5.923-1.546-1.603-3.46-2.404-5.74-2.404-2.272 0-4.19.801-5.754 2.404-1.565 1.583-2.347 3.538-2.347 5.865m-3.323-.036c0-3.111 1.126-5.784 3.38-8.018 2.242-2.234 4.938-3.351 8.086-3.351 3.11 0 5.778 1.127 8.002 3.38 2.234 2.252 3.351 4.959 3.351 8.119 0 3.178-1.121 5.875-3.365 8.09-2.252 2.224-4.972 3.335-8.158 3.335-2.818 0-5.348-.988-7.591-2.963-2.47-2.187-3.705-5.05-3.705-8.592"
        fill="#59C341"
      />
      <path
        d="M106.385 23.655h1.484c1.48 0 2.71-.158 3.69-.473a7.469 7.469 0 0 0 2.659-1.589c1.62-1.499 2.431-3.45 2.431-5.856 0-2.425-.8-4.391-2.403-5.9-1.442-1.346-3.568-2.018-6.377-2.018h-1.484v15.836zm-3.294 3.136V4.683h4.58c2.197 0 3.931.22 5.204.658 1.366.43 2.606 1.16 3.718 2.191 2.252 2.08 3.38 4.816 3.38 8.205 0 3.398-1.175 6.147-3.522 8.247-1.178 1.05-2.412 1.78-3.704 2.191-1.206.41-2.917.616-5.132.616h-4.524zm23.272-9.551l-7.125-12.557h3.79l4.99 8.834 5.004-8.834h3.79l-7.155 12.557v9.55h-3.294zM96.03 4.683v16.259L83.233 4.682h-3.174v22.109h3.295V10.503L96.15 26.791h3.174V4.683z"
        fill="#3D3D3D"
      />
      <g transform="translate(0 .14)">
        <mask id="b" fill="#fff">
          <use href="#a" />
        </mask>
        <path
          d="M14.926.218l-.443.267-5.056 3.064-.394.255V9.21l-4.107 2.483-.41.256v5.406L.41 19.839l-.41.255v7.068l.367.255 5.057 3.064.423.263.433-.259 4.211-2.48 4.16 2.478.439.262.439-.263 4.118-2.468 3.895 2.458.447.282.452-.272 5.172-3.065.496-.255v-7.068l-.409-.255-4.107-2.483V11.95l-.41-.256-4.106-2.483V3.803l-.524-.254L15.418.485l-.492-.267zm.128 1.02l5.12 3.065v5.406l4.515 2.738v5.407l4.517 2.739v6.07l-5.152 3.065-4.368-2.738-4.585 2.738-4.604-2.738-4.58 2.738-5.014-3.065v-6.07l4.516-2.739v-5.407L9.936 9.71V4.303l5.118-3.065z"
          fill="#3D3D3D"
          mask="url(#b)"
        />
      </g>
      <path
        fill="#59C341"
        d="M18.969 9.89l-3.915 2.413-3.913-2.413V5.066l3.913-2.413 3.915 2.413z"
      />
      <path
        fill="#3D3D3D"
        d="M18.969 26.18l-3.915 2.413-3.913-2.413v-4.824l3.913-2.413 3.915 2.413zm9.033 0l-3.915 2.413-3.914-2.413v-4.824l3.914-2.413 3.915 2.413zm-18.066 0L5.87 28.593 1.807 26.18v-4.824l4.064-2.413 4.065 2.413zm4.517-8.144l-3.915 2.412-3.914-2.412V13.21l3.914-2.413 3.915 2.413zm9.032 0l-3.914 2.412-3.914-2.412V13.21l3.914-2.413 3.914 2.413z"
      />
    </g>
  </svg>
);

export default FondyIcon;
