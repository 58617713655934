const NzdIcon = ({
  width = "100%",
  height = "100%",
  className = "",
}: {
  width?: string;
  height?: string;
  className?: string;
}) => {
  return (
    <svg
      className={className + " rounded-full fill-current"}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
    >
      <path d="M11.208 11.273v9.454H9.481l-4.113-5.95h-.07v5.95H3.3v-9.454h1.755l4.08 5.946h.084v-5.946h1.989Zm1.546 9.454v-1.186l4.719-6.62h-4.728v-1.648h7.238v1.186l-4.722 6.62h4.732v1.648h-7.24Zm13.9-6.735a1.167 1.167 0 0 0-.474-.868 1.886 1.886 0 0 0-1.14-.31 2.21 2.21 0 0 0-.827.137 1.163 1.163 0 0 0-.515.376.89.89 0 0 0-.178.545c-.01.155.028.31.109.443.083.131.194.242.325.325.15.097.312.176.48.236.19.069.384.125.582.168l.85.203c.39.086.77.21 1.135.37.326.142.628.333.895.568.25.22.45.491.59.794.144.33.217.687.214 1.048a2.62 2.62 0 0 1-.441 1.503 2.806 2.806 0 0 1-1.256.982 5.028 5.028 0 0 1-1.974.35 5.181 5.181 0 0 1-1.99-.351 2.896 2.896 0 0 1-1.321-1.041 3.085 3.085 0 0 1-.497-1.711h1.934c.01.285.106.561.275.791.168.216.394.381.651.476.296.11.61.164.926.16.301.006.6-.044.884-.148.228-.083.43-.224.584-.411a.948.948 0 0 0 .208-.605.791.791 0 0 0-.188-.535 1.455 1.455 0 0 0-.544-.37 5.644 5.644 0 0 0-.875-.277l-1.03-.258a4.315 4.315 0 0 1-1.888-.91 2.11 2.11 0 0 1-.687-1.666 2.47 2.47 0 0 1 .459-1.5 3.064 3.064 0 0 1 1.276-1.002 4.513 4.513 0 0 1 1.847-.36 4.38 4.38 0 0 1 1.84.36c.493.214.917.56 1.225 1.001.296.44.454.957.452 1.487h-1.916Zm-2.215 7.917.11-11.818h1.09l-.11 11.818h-1.09Z" />
    </svg>
  );
};

export default NzdIcon;
