const PSDIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 36 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.57141 0H24.4195L36 12.8665V43.0221C36 44.6067 34.8481 45.8902 33.4286 45.8902H2.57141C1.15194 45.8902 0 44.6067 0 43.0221V2.86811C0 1.28352 1.15207 0 2.57141 0Z"
      fill="#E2574C"
    />
    <path
      d="M8.04883 29.0586C8.66992 29.0586 9.02148 28.6777 9.02148 28.0156V26.4453H10.3105C12.1445 26.4453 13.2695 25.4668 13.2695 23.7148C13.2695 22.0684 12.1621 21.002 10.4102 21.002H8.35352C7.43945 21.002 7.08789 21.3711 7.08789 22.3262V28.0156C7.08789 28.6836 7.43359 29.0586 8.04883 29.0586ZM9.02734 25.0039V22.4961H10.123C10.9141 22.4961 11.3535 22.9531 11.3535 23.75C11.3535 24.6758 10.9082 25.0039 9.90625 25.0039H9.02734ZM13.9375 27.3535C13.9375 27.6816 14.125 28.0215 14.4648 28.3438C15.0098 28.8594 15.9883 29.1758 17.0898 29.1758C19.0938 29.1758 20.377 28.1621 20.377 26.5859C20.377 25.373 19.6562 24.6406 18.168 24.3418L17.0547 24.1016C16.3105 23.9551 15.9707 23.6445 15.9707 23.2051C15.9707 22.7012 16.416 22.3789 17.125 22.3789C17.7285 22.3789 18.2031 22.6133 18.6133 23.0645C18.8594 23.2988 19.1113 23.4102 19.3633 23.4102C19.8145 23.4102 20.1191 23.1172 20.1191 22.6895C20.1191 22.332 19.9375 21.9805 19.5918 21.6641C19.0527 21.1543 18.127 20.8555 17.1016 20.8555C15.2266 20.8555 14.0312 21.8281 14.0312 23.3633C14.0312 24.5879 14.7402 25.332 16.1816 25.6367L17.2832 25.8887C18.127 26.0703 18.4785 26.3457 18.4785 26.791C18.4785 27.3418 18.0273 27.6465 17.1895 27.6465C16.5977 27.6465 16.0176 27.4004 15.5371 26.9492C15.2148 26.6621 14.9746 26.5566 14.6934 26.5566C14.2773 26.5566 13.9375 26.8848 13.9375 27.3535ZM21.5488 27.8926C21.5488 28.6133 21.9473 29 22.6797 29H24.6895C27.0449 29 28.3047 27.6055 28.3047 24.998C28.3047 22.3965 27.0449 21.002 24.6895 21.002H22.6797C21.9473 21.002 21.5488 21.3887 21.5488 22.1094V27.8926ZM23.4883 27.459V22.5371H24.3906C25.7207 22.5371 26.3242 23.3047 26.3242 24.998C26.3242 26.6973 25.7207 27.459 24.3906 27.459H23.4883Z"
      fill="white"
    />
    <path
      d="M35.958 12.9116H26.9952C25.5758 12.9116 24.4238 11.6268 24.4238 10.0435V0.0336914L35.958 12.9116Z"
      fill="#B53629"
    />
  </svg>
);

export default PSDIcon;
