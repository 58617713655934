const CopIcon = ({
  width = "100%",
  height = "100%",
  className = "",
}: {
  width?: string;
  height?: string;
  className?: string;
}) => {
  return (
    <svg
      className={className + " rounded-full fill-current"}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
    >
      <path d="M19.097 12.548a2.088 2.088 0 0 0-.938-1.566 3.62 3.62 0 0 0-2.074-.557 3.903 3.903 0 0 0-1.548.28c-.4.16-.75.425-1.012.767a1.813 1.813 0 0 0-.359 1.105c-.008.316.077.627.245.894.167.255.388.47.647.63.271.17.56.31.863.418.291.106.587.198.888.274l1.42.369c.486.12.963.275 1.427.462.485.192.942.45 1.357.766.416.318.761.72 1.012 1.18.27.519.403 1.098.387 1.683a3.88 3.88 0 0 1-.636 2.195 4.288 4.288 0 0 1-1.84 1.512 6.92 6.92 0 0 1-2.908.554 7.06 7.06 0 0 1-2.826-.518 4.338 4.338 0 0 1-1.868-1.474 4.246 4.246 0 0 1-.746-2.27h2.202c.025.482.205.942.515 1.311.311.356.71.623 1.158.774a4.577 4.577 0 0 0 1.552.253 4.37 4.37 0 0 0 1.658-.295 2.73 2.73 0 0 0 1.146-.824c.28-.352.428-.79.42-1.24a1.533 1.533 0 0 0-.366-1.058 2.78 2.78 0 0 0-.987-.682 9.888 9.888 0 0 0-1.403-.476l-1.718-.468a6.654 6.654 0 0 1-2.767-1.4 3.14 3.14 0 0 1-1.02-2.443 3.634 3.634 0 0 1 .682-2.194 4.505 4.505 0 0 1 1.847-1.46 6.356 6.356 0 0 1 2.628-.521 6.216 6.216 0 0 1 2.61.518c.71.31 1.326.8 1.786 1.424.437.605.675 1.33.682 2.077h-2.116Zm-3.75 12.543.056-18.182h1.335l-.056 18.182h-1.335Z" />
    </svg>
  );
};

export default CopIcon;
