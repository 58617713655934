const EurIcon = ({
  width = "100%",
  height = "100%",
  className = "",
}: {
  width?: string;
  height?: string;
  className?: string;
}) => {
  return (
    <svg
      className={className + " rounded-full fill-current"}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
    >
      <path d="m18.111 13.827-.675 1.463h-9.34l.534-1.463h9.481ZM16.84 16.71l-.689 1.477H8.097l.533-1.477h8.21Zm3.005-6.74-.84 1.797a6.103 6.103 0 0 0-.865-.618 4.702 4.702 0 0 0-1.01-.444 3.893 3.893 0 0 0-1.156-.167 3.486 3.486 0 0 0-2.025.61 4.021 4.021 0 0 0-1.395 1.83 7.894 7.894 0 0 0-.508 3.029 7.864 7.864 0 0 0 .508 3.025c.277.73.762 1.364 1.395 1.822a3.5 3.5 0 0 0 2.024.607c.396.004.79-.051 1.168-.163a4.46 4.46 0 0 0 .995-.43c.285-.166.557-.355.813-.564l.86 1.79a5.061 5.061 0 0 1-1.748 1.03 6.274 6.274 0 0 1-2.088.348 5.622 5.622 0 0 1-3.157-.903 6.047 6.047 0 0 1-2.155-2.574 9.358 9.358 0 0 1-.781-3.988 9.403 9.403 0 0 1 .781-3.999 6.042 6.042 0 0 1 2.155-2.578 5.625 5.625 0 0 1 3.157-.902 5.9 5.9 0 0 1 2.117.373 5.567 5.567 0 0 1 1.755 1.069Z" />
    </svg>
  );
};

export default EurIcon;
