const SarIcon = ({
  width = "100%",
  height = "100%",
  className = "",
}: {
  width?: string;
  height?: string;
  className?: string;
}) => {
  const regex = /\btext-([a-zA-Z0-9-]+)\b/;
  const matches = className.match(regex);
  const textClass = matches ? matches[0] : "text-white";
  return (
    <svg
      className={className + " rounded-full fill-current"}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
    >
      <g
        transform="translate(7,23.000000) scale(0.003500, -0.003500)"
        fill={textClass}
        stroke="none"
      >
        <path
          d="M1548 3803 c-28 -32 -58 -101 -74 -170 -24 -102 -13 -221 41 -458 78
-340 110 -552 166 -1100 59 -580 40 -741 -104 -871 -133 -120 -355 -170 -711
-161 -219 5 -297 20 -401 75 -63 34 -136 106 -166 163 -52 98 -11 273 122 529
60 114 70 140 65 169 -12 75 -98 120 -168 87 -59 -28 -160 -209 -233 -417
-137 -393 -112 -624 89 -825 168 -167 361 -214 816 -200 417 12 603 70 776
241 68 67 89 95 127 175 54 112 75 193 87 332 13 149 9 197 -130 1412 -17 149
-28 284 -24 303 4 20 28 62 56 96 132 164 137 279 16 393 -24 23 -63 54 -88
69 -24 16 -79 61 -122 102 -81 77 -111 89 -140 56z"
        />
        <path
          d="M2263 3512 c-19 -12 -114 -241 -125 -303 -13 -67 6 -218 82 -647 54
-309 69 -417 100 -712 48 -455 74 -553 167 -641 98 -94 133 -98 839 -98 475
-1 503 0 525 18 20 16 24 30 30 132 11 172 10 537 -2 647 -25 231 -91 403
-199 513 -65 66 -89 72 -126 32 -49 -53 -87 -148 -92 -231 -5 -93 15 -154 87
-264 61 -94 74 -129 105 -277 14 -67 26 -122 26 -124 0 -7 -959 0 -980 7 -54
18 -93 71 -104 141 -3 22 -26 299 -51 615 -34 419 -53 607 -70 693 -33 161
-125 467 -147 489 -19 19 -45 23 -65 10z"
        />
        <path
          d="M4355 2276 c-37 -17 -80 -62 -94 -99 -6 -16 -11 -52 -11 -80 0 -60
17 -93 95 -182 127 -145 360 -445 387 -500 76 -149 76 -292 1 -425 -51 -89
-197 -273 -292 -368 -166 -165 -333 -270 -526 -328 -82 -25 -105 -27 -255 -28
-151 -1 -178 2 -319 32 -153 34 -154 34 -180 14 -32 -23 -40 -67 -14 -82 10
-6 90 -36 178 -66 409 -143 495 -164 649 -164 326 1 584 170 904 595 120 159
188 284 217 395 23 87 31 309 15 406 -28 178 -80 282 -245 489 -227 285 -297
364 -340 385 -48 23 -125 26 -170 6z"
        />
        <path
          d="M2703 909 c-99 -90 -123 -116 -123 -138 0 -20 30 -62 116 -162 63
-73 122 -138 131 -143 24 -13 51 5 175 117 l115 106 76 -87 c116 -135 133
-152 156 -152 32 0 271 226 271 256 0 15 -33 61 -92 130 -132 153 -153 174
-175 174 -12 0 -72 -48 -144 -115 -68 -63 -126 -114 -129 -114 -3 0 -36 38
-75 85 -110 134 -131 154 -156 154 -16 0 -64 -36 -146 -111z"
        />
      </g>
    </svg>
  );
};

export default SarIcon;
