const WaylightIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="18" height="18" rx="9" fill="#0F172A" />
    <path
      d="M5.19711 13H7.56242L8.95007 7.96875H9.04993L10.4376 13H12.8081L15 5H12.7398L11.5099 10.3438H11.4415L10.117 5H7.89356L6.59001 10.375H6.51643L5.26544 5H3L5.19711 13Z"
      fill="#FCFDFF"
    />
  </svg>
);

export default WaylightIcon;
