const config = {
  stripeSupportedCountries: [
    { label: "Argentina", code: "AR" },
    { label: "Australia", code: "AU" },
    { label: "Austria", code: "AT" },
    { label: "Belgium", code: "BE" },
    { label: "Bolivia", code: "BO" },
    { label: "Bulgaria", code: "BG" },
    { label: "Canada", code: "CA" },
    { label: "Chile", code: "CL" },
    { label: "Colombia", code: "CO" },
    { label: "Costa Rica", code: "CR" },
    { label: "Croatia", code: "HR" },
    { label: "Cyprus", code: "CY" },
    { label: "Czech Republic", code: "CZ" },
    { label: "Denmark", code: "DK" },
    { label: "Dominican Republic", code: "DO" },
    { label: "Egypt", code: "EG" },
    { label: "Estonia", code: "EE" },
    { label: "Finland", code: "FI" },
    { label: "France", code: "FR" },
    { label: "Germany", code: "DE" },
    { label: "Greece", code: "GR" },
    { label: "Hong Kong SAR China", code: "HK" },
    { label: "Hungary", code: "HU" },
    { label: "Iceland", code: "IS" },
    { label: "India", code: "IN" },
    { label: "Indonesia", code: "ID" },
    { label: "Ireland", code: "IE" },
    { label: "Israel", code: "IL" },
    { label: "Italy", code: "IT" },
    { label: "Kenya", code: "KE" },
    { label: "Latvia", code: "LV" },
    { label: "Liechtenstein", code: "LI" },
    { label: "Lithuania", code: "LT" },
    { label: "Luxembourg", code: "LU" },
    { label: "Malta", code: "MT" },
    { label: "Mexico", code: "MX" },
    { label: "Netherlands", code: "NL" },
    { label: "New Zealand", code: "NZ" },
    { label: "Norway", code: "NO" },
    { label: "Paraguay", code: "PY" },
    { label: "Peru", code: "PE" },
    { label: "Philippines", code: "PH" },
    { label: "Poland", code: "PL" },
    { label: "Portugal", code: "PT" },
    { label: "Romania", code: "RO" },
    { label: "Saudi Arabia", code: "SA" },
    { label: "Singapore", code: "SG" },
    { label: "Slovakia", code: "SK" },
    { label: "Slovenia", code: "SI" },
    { label: "South Africa", code: "ZA" },
    { label: "Spain", code: "ES" },
    { label: "Sweden", code: "SE" },
    { label: "Switzerland", code: "CH" },
    { label: "Thailand", code: "TH" },
    { label: "Trinidad & Tobago", code: "TT" },
    { label: "Turkey", code: "TR" },
    { label: "United Arab Emirates", code: "AE" },
    { label: "United Kingdom", code: "GB" },
    { label: "United States", code: "US" },
    { label: "Ukraine", code: "UA" },
    { label: "Uruguay", code: "UY" },
  ],
};

export default config;
