const PDFIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 36 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.57141 0H24.4195L36 12.8665V43.0221C36 44.6067 34.8481 45.8902 33.4286 45.8902H2.57141C1.15194 45.8902 0 44.6067 0 43.0221V2.86811C0 1.28352 1.15207 0 2.57141 0Z"
      fill="#E2574C"
    />
    <path
      d="M8.97656 29.0586C9.59766 29.0586 9.94922 28.6777 9.94922 28.0156V26.4453H11.2383C13.0723 26.4453 14.1973 25.4668 14.1973 23.7148C14.1973 22.0684 13.0898 21.002 11.3379 21.002H9.28125C8.36719 21.002 8.01562 21.3711 8.01562 22.3262V28.0156C8.01562 28.6836 8.36133 29.0586 8.97656 29.0586ZM9.95508 25.0039V22.4961H11.0508C11.8418 22.4961 12.2812 22.9531 12.2812 23.75C12.2812 24.6758 11.8359 25.0039 10.834 25.0039H9.95508ZM15.2109 27.8926C15.2109 28.6133 15.6094 29 16.3418 29H18.3516C20.707 29 21.9668 27.6055 21.9668 24.998C21.9668 22.3965 20.707 21.002 18.3516 21.002H16.3418C15.6094 21.002 15.2109 21.3887 15.2109 22.1094V27.8926ZM17.1504 27.459V22.5371H18.0527C19.3828 22.5371 19.9863 23.3047 19.9863 24.998C19.9863 26.6973 19.3828 27.459 18.0527 27.459H17.1504ZM24.123 29.0586C24.7324 29.0586 25.0723 28.6836 25.0723 28.0156V25.9883H27.5332C27.9902 25.9883 28.3008 25.6836 28.3008 25.2324C28.3008 24.7812 27.9961 24.4766 27.5332 24.4766H25.0723V22.5547H27.832C28.2773 22.5547 28.5996 22.2383 28.5996 21.7871C28.5996 21.3301 28.2773 21.002 27.832 21.002H24.252C23.5254 21.002 23.1328 21.3945 23.1328 22.1094V28.0156C23.1328 28.6719 23.4961 29.0586 24.123 29.0586Z"
      fill="white"
    />
    <path
      d="M35.958 12.9116H26.9952C25.5758 12.9116 24.4238 11.6268 24.4238 10.0435V0.0336914L35.958 12.9116Z"
      fill="#B53629"
    />
  </svg>
);

export default PDFIcon;
