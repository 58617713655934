const UahIcon = ({
  width = "100%",
  height = "100%",
  className = "",
}: {
  width?: string;
  height?: string;
  className?: string;
}) => {
  return (
    <svg
      className={className + " rounded-full fill-current"}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
    >
      <path d="M21.373 15.268H10.627v-1.726h10.746v1.726Zm0 2.99H10.627v-1.704h10.746v1.704Zm-5.37 5.214a5.62 5.62 0 0 1-3.391-.895 3.001 3.001 0 0 1-1.218-2.55 2.93 2.93 0 0 1 .583-1.68c.18-.244.4-.454.653-.621l2.024.355c-.31.241-.567.545-.753.891a2.242 2.242 0 0 0-.235.977 1.395 1.395 0 0 0 .616 1.19 2.948 2.948 0 0 0 1.721.429 3.19 3.19 0 0 0 1.737-.43 1.59 1.59 0 0 0 .692-1.43h2.309a3.798 3.798 0 0 1-.593 2.127c-.4.57-.967 1.002-1.623 1.235a7.067 7.067 0 0 1-2.522.402Zm.057-13.069a2.369 2.369 0 0 0-1.672.5 1.818 1.818 0 0 0-.544 1.354h-2.3a4.106 4.106 0 0 1 .493-1.897 3.262 3.262 0 0 1 1.449-1.338 5.718 5.718 0 0 1 2.56-.494 6.26 6.26 0 0 1 2.355.394 3.13 3.13 0 0 1 1.474 1.147 3.21 3.21 0 0 1 .503 1.825c0 .288-.044.575-.13.85a2.99 2.99 0 0 1-.384.784 3.31 3.31 0 0 1-.608.668l-1.896-.54c.223-.252.421-.524.594-.813.16-.27.243-.578.237-.891a1.343 1.343 0 0 0-.53-1.126 2.554 2.554 0 0 0-1.6-.423Z" />
    </svg>
  );
};

export default UahIcon;
