const BobIcon = ({
  width = "100%",
  height = "100%",
  className = "",
}: {
  width?: string;
  height?: string;
  className?: string;
}) => {
  const regex = /\btext-([a-zA-Z0-9-]+)\b/;
  const matches = className.match(regex);
  const textClass = matches ? matches[0] : "text-white";
  return (
    <svg
      className={className + " rounded-full fill-current"}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
    >
      <g
        transform="translate(5,25.000000) scale(0.004000, -0.004000)"
        fill={textClass}
        stroke="none"
      >
        <path
          d="M1014 5011 c-54 -25 -101 -83 -114 -143 -5 -27 -10 -168 -10 -318 l0
  -268 -72 -12 c-215 -37 -443 -166 -567 -322 -150 -189 -208 -408 -189 -714 26
  -428 211 -615 793 -801 l30 -10 3 -571 c1 -315 1 -572 0 -572 -2 0 -24 6 -49
  14 -138 42 -262 140 -322 253 -45 86 -65 166 -77 298 -10 110 -28 161 -72 202
  -79 74 -230 72 -310 -4 -49 -47 -62 -99 -55 -226 24 -429 224 -750 553 -887
  65 -27 209 -67 312 -86 l22 -4 0 -270 c0 -149 5 -291 10 -318 35 -160 237
  -216 358 -99 56 54 62 95 62 408 l0 278 38 6 c154 26 340 101 449 182 293 218
  433 550 402 956 -14 195 -50 310 -134 438 -105 157 -282 265 -640 388 l-110
  38 -3 502 -2 502 22 -6 c124 -36 231 -122 283 -229 34 -67 64 -189 65 -261 0
  -16 13 -53 30 -81 80 -140 304 -139 378 1 27 52 31 97 21 198 -29 285 -140
  501 -323 631 -110 78 -222 124 -381 155 l-90 18 -5 299 c-4 212 -9 308 -18
  329 -45 104 -181 154 -288 106z m-124 -1591 l0 -440 -57 21 c-201 73 -301 150
  -322 250 -16 74 -14 227 5 290 44 152 162 253 372 318 1 1 2 -197 2 -439z
  m575 -1197 c242 -106 308 -196 307 -418 -2 -259 -126 -438 -353 -510 -44 -14
  -84 -25 -90 -25 -5 0 -9 194 -9 506 l0 505 23 -7 c12 -4 67 -26 122 -51z"
        />
        <path
          d="M2609 4291 l-24 -19 -6 -1854 c-3 -1019 -9 -1863 -13 -1875 -10 -28
  -1 -66 20 -87 13 -14 50 -16 243 -16 206 0 229 2 244 18 12 14 18 50 23 154
  l7 135 66 -69 c162 -173 363 -264 639 -290 247 -23 494 33 706 162 335 203
  543 559 596 1021 19 162 9 476 -20 609 -111 523 -408 859 -855 968 -55 13
  -113 17 -265 16 -177 0 -204 -3 -292 -27 -174 -46 -327 -130 -447 -244 l-61
  -59 0 716 c0 602 -2 719 -14 736 -13 18 -30 19 -268 22 -240 3 -256 2 -279
  -17z m1456 -1596 c293 -76 534 -359 600 -706 17 -94 20 -283 6 -371 -24 -139
  -90 -310 -161 -413 -83 -121 -204 -225 -332 -285 -150 -71 -349 -79 -505 -22
  -351 129 -583 518 -560 941 25 456 311 812 702 875 51 8 187 -2 250 -19z"
        />
      </g>
    </svg>
  );
};

export default BobIcon;
