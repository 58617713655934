const StripeIcon = ({
  width = "60px",
  height = "25px",
}: {
  width?: string;
  height?: string;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 60 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.21667 9.78333C4.21667 9.13333 4.75 8.88333 5.63333 8.88333C6.9 8.88333 8.5 9.26667 9.76667 9.95V6.03333C8.38333 5.48333 7.01667 5.26667 5.63333 5.26667C2.25 5.26667 0 7.03333 0 9.98333C0 14.5833 6.33333 13.85 6.33333 15.8333C6.33333 16.6 5.66667 16.85 4.73333 16.85C3.35 16.85 1.58333 16.2833 0.183333 15.5167V19.4833C1.73333 20.15 3.3 20.4333 4.73333 20.4333C8.2 20.4333 10.5833 18.7167 10.5833 15.7333C10.5667 10.7667 4.21667 11.65 4.21667 9.78333ZM15.4833 1.93333L11.4167 2.8L11.4 16.15C11.4 18.6167 13.25 20.4333 15.7167 20.4333C17.0833 20.4333 18.0833 20.1833 18.6333 19.8833V16.5C18.1 16.7167 15.4667 17.4833 15.4667 15.0167V9.1H18.6333V5.55H15.4667L15.4833 1.93333ZM23.8167 6.78333L23.55 5.55H19.95V20.1333H24.1167V10.25C25.1 8.96667 26.7667 9.2 27.2833 9.38333V5.55C26.75 5.35 24.8 4.98333 23.8167 6.78333ZM28.3 5.55H32.4833V20.1333H28.3V5.55ZM28.3 4.28333L32.4833 3.38333V0L28.3 0.883333V4.28333ZM41.1833 5.26667C39.55 5.26667 38.5 6.03333 37.9167 6.56667L37.7 5.53333H34.0333V24.9667L38.2 24.0833L38.2167 19.3667C38.8167 19.8 39.7 20.4167 41.1667 20.4167C44.15 20.4167 46.8667 18.0167 46.8667 12.7333C46.85 7.9 44.1 5.26667 41.1833 5.26667ZM40.1833 16.75C39.2 16.75 38.6167 16.4 38.2167 15.9667L38.2 9.78333C38.6333 9.3 39.2333 8.96667 40.1833 8.96667C41.7 8.96667 42.75 10.6667 42.75 12.85C42.75 15.0833 41.7167 16.75 40.1833 16.75ZM60 12.9C60 8.63333 57.9333 5.26667 53.9833 5.26667C50.0167 5.26667 47.6167 8.63333 47.6167 12.8667C47.6167 17.8833 50.45 20.4167 54.5167 20.4167C56.5 20.4167 58 19.9667 59.1333 19.3333V16C58 16.5667 56.7 16.9167 55.05 16.9167C53.4333 16.9167 52 16.35 51.8167 14.3833H59.9667C59.9667 14.1667 60 13.3 60 12.9ZM51.7667 11.3167C51.7667 9.43333 52.9167 8.65 53.9667 8.65C54.9833 8.65 56.0667 9.43333 56.0667 11.3167H51.7667Z"
      fill="#6772E5"
    />
  </svg>
);

export default StripeIcon;
