const PPTXIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 36 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.57141 0H24.4195L36 12.8665V43.0221C36 44.6067 34.8481 45.8902 33.4286 45.8902H2.57141C1.15194 45.8902 0 44.6067 0 43.0221V2.86811C0 1.28352 1.15207 0 2.57141 0Z"
      fill="#E2574C"
    />
    <path
      d="M5.08984 29.0586C5.71094 29.0586 6.0625 28.6777 6.0625 28.0156V26.4453H7.35156C9.18555 26.4453 10.3105 25.4668 10.3105 23.7148C10.3105 22.0684 9.20312 21.002 7.45117 21.002H5.39453C4.48047 21.002 4.12891 21.3711 4.12891 22.3262V28.0156C4.12891 28.6836 4.47461 29.0586 5.08984 29.0586ZM6.06836 25.0039V22.4961H7.16406C7.95508 22.4961 8.39453 22.9531 8.39453 23.75C8.39453 24.6758 7.94922 25.0039 6.94727 25.0039H6.06836ZM12.2852 29.0586C12.9062 29.0586 13.2578 28.6777 13.2578 28.0156V26.4453H14.5469C16.3809 26.4453 17.5059 25.4668 17.5059 23.7148C17.5059 22.0684 16.3984 21.002 14.6465 21.002H12.5898C11.6758 21.002 11.3242 21.3711 11.3242 22.3262V28.0156C11.3242 28.6836 11.6699 29.0586 12.2852 29.0586ZM13.2637 25.0039V22.4961H14.3594C15.1504 22.4961 15.5898 22.9531 15.5898 23.75C15.5898 24.6758 15.1445 25.0039 14.1426 25.0039H13.2637ZM21.0098 29.0586C21.6133 29.0586 21.9824 28.6719 21.9824 28.0391V22.5898H23.5117C23.957 22.5898 24.2852 22.25 24.2852 21.7988C24.2852 21.3418 23.957 21.002 23.5117 21.002H18.5254C18.0801 21.002 17.7461 21.3418 17.7461 21.7988C17.7461 22.25 18.0801 22.5898 18.5254 22.5898H20.043V28.0391C20.043 28.6719 20.4062 29.0586 21.0098 29.0586ZM24.7129 28.1914C24.7129 28.6836 25.1055 29.0586 25.6211 29.0586C25.9902 29.0586 26.1836 28.9121 26.5938 28.332L27.9883 26.3164H28.0762L29.5469 28.332C30.0039 28.9355 30.168 29.0586 30.5254 29.0586C31.0469 29.0586 31.4453 28.6836 31.4453 28.1914C31.4453 27.9746 31.3574 27.7578 31.1699 27.5176L29.2188 25.0273L31.1348 22.4727C31.3281 22.2207 31.416 22.0098 31.416 21.8047C31.416 21.3125 31.0234 20.9316 30.502 20.9316C30.1211 20.9316 29.9043 21.0957 29.5352 21.6582L28.1523 23.7617H28.0645L26.5996 21.6582C26.1777 21.0723 25.9902 20.9316 25.6211 20.9316C25.0996 20.9316 24.7012 21.3066 24.7012 21.8047C24.7012 22.0215 24.7891 22.2383 24.9766 22.4727L26.8633 24.9746L24.9883 27.5234C24.8008 27.7754 24.7129 27.9863 24.7129 28.1914Z"
      fill="white"
    />
    <path
      d="M35.958 12.9116H26.9952C25.5758 12.9116 24.4238 11.6268 24.4238 10.0435V0.0336914L35.958 12.9116Z"
      fill="#B53629"
    />
  </svg>
);

export default PPTXIcon;
