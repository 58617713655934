import { uk, enUS, ru } from "date-fns/locale";
import { format } from "date-fns";

export const formatWithLocale = (
  date: Date | number,
  _format: string,
  options?: {
    locale?: any;
  }
): string => {
  const hasWindow = typeof window !== "undefined";
  const language = hasWindow ? localStorage?.getItem("lang") : null;
  const code = JSON.parse(language)?.code;
  let locale;
  switch (code) {
    case "UA": {
      locale = uk;
      break;
    }
    case "RU": {
      locale = ru;
      break;
    }
    default: {
      locale = enUS;
      break;
    }
  }

  return format(date, _format, { locale, ...options });
};
