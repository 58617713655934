const ArsIcon = ({
  width = "100%",
  height = "100%",
  className = "",
}: {
  width?: string;
  height?: string;
  className?: string;
}) => {
  return (
    <svg
      className={className + " rounded-full fill-current"}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
    >
      <path d="M6.293 11.273 3.03 20.727h2.14l.702-2.155h3.414l.7 2.155h2.141L8.87 11.273H6.293Zm.087 5.738 1.164-3.578h.075l1.161 3.578h-2.4Zm12.422.005c.488-.218.898-.58 1.175-1.037a3.089 3.089 0 0 0 .406-1.618 3.216 3.216 0 0 0-.4-1.632 2.688 2.688 0 0 0-1.158-1.075 4.043 4.043 0 0 0-1.83-.381h-3.73v9.454h1.998v-3.351h1.46l1.79 3.351h2.207l-2.01-3.682c.028-.012.064-.016.092-.03Zm-3.539-4.109h1.349c.327-.01.654.047.958.168.237.099.436.271.567.492.132.243.197.518.188.794.01.272-.055.542-.188.78-.131.215-.33.38-.565.471a2.489 2.489 0 0 1-.951.157h-1.358v-2.862Zm13.492 4.072a2.4 2.4 0 0 0-.588-.794 3.438 3.438 0 0 0-.896-.568 6.027 6.027 0 0 0-1.136-.37l-.274-.065.022-2.299c.203.042.396.123.567.24a1.168 1.168 0 0 1 .474.869h1.916a2.651 2.651 0 0 0-.452-1.487 2.906 2.906 0 0 0-1.225-1.001 4.108 4.108 0 0 0-1.265-.308l.01-1.105H24.82l-.01 1.1a4.32 4.32 0 0 0-1.334.313 3.064 3.064 0 0 0-1.276 1.001 2.47 2.47 0 0 0-.46 1.5 2.109 2.109 0 0 0 .688 1.667 4.314 4.314 0 0 0 1.889.91l.44.11-.022 2.44a2.257 2.257 0 0 1-.384-.106 1.474 1.474 0 0 1-.651-.476 1.43 1.43 0 0 1-.275-.791h-1.934a3.086 3.086 0 0 0 .497 1.71c.327.47.787.833 1.32 1.041.453.171.928.273 1.41.303l-.01 1.096h1.09l.01-1.09a4.718 4.718 0 0 0 1.465-.307 2.8 2.8 0 0 0 1.256-.983c.297-.444.45-.968.441-1.503.004-.36-.07-.717-.215-1.047Zm-4.05-2.103a2.321 2.321 0 0 1-.48-.236 1.052 1.052 0 0 1-.326-.325.757.757 0 0 1-.108-.443.889.889 0 0 1 .177-.545c.134-.17.312-.301.515-.376.1-.038.205-.066.31-.085l-.018 2.03c-.023-.008-.048-.012-.07-.02Zm2.04 3.75a1.356 1.356 0 0 1-.584.412c-.11.04-.222.07-.337.09l.02-2.148c.137.044.264.09.377.137.207.08.393.207.544.37a.79.79 0 0 1 .188.535.947.947 0 0 1-.208.605Z" />
    </svg>
  );
};

export default ArsIcon;
