import InstagramIcon from "./InstagramIcon/InstagramIcon";
import PDFIcon from "./PDFIcon/PDFIcon";
import EmptyDocIcon from "./EmptyDocIcon/EmptyDocIcon";
import PPTXIcon from "./PPTXIcon/PPTXIcon";
import PPTIcon from "./PPTIcon/PPTIcon";
import KEYIcon from "./KEYIcon/KEYIcon";
import DOCIcon from "./DOCIcon/DOCIcon";
import DOCXIcon from "./DOCXIcon/DOCXIcon";
import PSDIcon from "./PSDIcon/PSDIcon";
import AIIcon from "./AIIcon/AIIcon";
import XLSIcon from "./XLSIcon/XLSIcon";
import XLSXIcon from "./XLSXIcon/XLSXIcon";
import EPUBIcon from "./EPUBIcon/EPUBIcon";
import MOBIIcon from "./MOBIIcon/MOBIIcon";
import TXTIcon from "./TXTIcon/TXTIcon";
import RTFIcon from "./RTFIcon/RTFIcon";
import CSVIcon from "./CSVIcon/CSVIcon";
import MP3Icon from "./MP3Icon/MP3Icon";
import WAVIcon from "./WAVIcon/WAVIcon";
import WaylightIcon from "./WaylightIcon/WaylightIcon";
import FondyIcon from "./FondyIcon/FondyIcon";
import StripeIcon from "./StripeIcon/StripeIcon";
import HourglassIcon from "./Hourglass/HourglassIcon";
import AccessIcon from "./AccessIcon/AccessIcon";
import HryvniaIcon from "./HryvniaIcon/HryvniaIcon";

export {
  InstagramIcon,
  PDFIcon,
  EmptyDocIcon,
  PPTXIcon,
  PPTIcon,
  KEYIcon,
  DOCIcon,
  DOCXIcon,
  PSDIcon,
  AIIcon,
  XLSIcon,
  XLSXIcon,
  EPUBIcon,
  MOBIIcon,
  TXTIcon,
  RTFIcon,
  CSVIcon,
  MP3Icon,
  WAVIcon,
  FondyIcon,
  StripeIcon,
  WaylightIcon,
  HourglassIcon,
  AccessIcon,
  HryvniaIcon,
};
