const UyuIcon = ({
  width = "100%",
  height = "100%",
  className = "",
}: {
  width?: string;
  height?: string;
  className?: string;
}) => {
  const regex = /\btext-([a-zA-Z0-9-]+)\b/;
  const matches = className.match(regex);
  const textClass = matches ? matches[0] : "text-white";
  return (
    <svg
      className={className + " rounded-full fill-current"}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
    >
      <g
        transform="translate(6.5,24.500000) scale(0.003500, -0.003500)"
        fill={textClass}
        stroke="none"
      >
        <path
          d="M964 4896 c-39 -17 -92 -81 -104 -126 -6 -19 -10 -155 -10 -301 l0
-266 -62 -12 c-253 -48 -478 -192 -601 -384 -108 -168 -155 -402 -128 -635 24
-204 72 -320 178 -433 106 -112 249 -190 501 -273 l112 -38 0 -544 c0 -299 -2
-544 -3 -544 -2 0 -28 8 -58 19 -242 83 -350 240 -375 541 -7 95 -13 111 -58
158 -46 50 -83 65 -152 65 -68 -1 -104 -16 -151 -65 -43 -45 -53 -76 -53 -167
0 -204 76 -453 184 -604 140 -198 330 -305 643 -363 22 -4 22 -5 25 -293 l3
-289 30 -43 c42 -59 76 -81 134 -87 75 -7 118 5 162 45 70 63 69 60 69 376 l0
284 63 12 c93 18 182 49 282 99 261 130 429 356 497 667 7 36 13 127 13 225 0
141 -4 179 -24 260 -75 300 -260 463 -685 606 l-146 49 0 477 c0 263 3 478 8
478 4 0 37 -13 74 -28 162 -66 249 -195 273 -407 13 -107 47 -162 120 -196 85
-39 194 -15 252 56 43 51 53 102 43 200 -39 360 -172 565 -452 694 -80 37
-237 81 -292 81 l-26 0 0 290 c0 232 -3 297 -14 318 -19 37 -75 89 -109 101
-39 15 -126 13 -163 -3z m-114 -1520 l0 -415 -70 24 c-98 33 -188 83 -236 130
-53 54 -69 110 -68 245 1 120 20 182 80 263 38 52 141 125 214 150 88 30 80
70 80 -397z m606 -1166 c118 -57 181 -118 211 -203 28 -80 25 -260 -5 -349
-61 -176 -184 -281 -379 -323 l-33 -7 0 485 0 485 53 -19 c28 -11 98 -42 153
-69z"
        />
        <path
          d="M2685 4140 c-66 -25 -127 -93 -151 -168 -17 -53 -18 -119 -22 -952
-3 -942 3 -1172 38 -1367 54 -302 140 -483 310 -652 166 -164 350 -254 642
-313 100 -20 142 -23 358 -23 235 0 249 1 346 27 254 68 387 142 568 317 166
161 246 306 295 531 46 208 46 211 46 1335 l0 1070 -33 67 c-52 105 -144 155
-268 145 -77 -6 -157 -47 -191 -98 -60 -91 -57 -39 -64 -1179 -5 -929 -8
-1054 -23 -1130 -36 -181 -86 -301 -172 -415 -134 -179 -272 -232 -584 -222
-186 5 -222 14 -330 77 -89 52 -153 116 -225 225 -63 96 -85 152 -117 299 -22
99 -22 119 -28 1151 -6 984 -7 1054 -25 1108 -26 81 -91 150 -164 171 -72 21
-144 20 -206 -4z"
        />
      </g>
    </svg>
  );
};

export default UyuIcon;
