const DkkIcon = ({
  width = "100%",
  height = "100%",
  className = "",
}: {
  width?: string;
  height?: string;
  className?: string;
}) => {
  return (
    <svg
      className={className + " rounded-full fill-current"}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
    >
      <path d="M4.814 23.205V8.66h2.195v6.946h.177l6.101-6.946h2.763l-5.866 6.548 5.888 7.997h-2.643l-4.701-6.498-1.719 1.974v4.524H4.814Zm13.118 0V12.296h2.053v1.733h.113a2.652 2.652 0 0 1 1.055-1.388 3.003 3.003 0 0 1 1.715-.508 9.02 9.02 0 0 1 .902.05v2.03a4.18 4.18 0 0 0-.454-.08 4.68 4.68 0 0 0-.654-.047c-.466-.008-.926.1-1.338.316a2.37 2.37 0 0 0-1.269 2.141v6.662h-2.123Zm7.834.135a1.425 1.425 0 0 1-1.315-1.963c.072-.171.178-.326.313-.455a1.415 1.415 0 0 1 2.003 0 1.4 1.4 0 0 1 .224 1.712 1.49 1.49 0 0 1-.515.515c-.214.128-.46.194-.71.191Z" />
    </svg>
  );
};

export default DkkIcon;
