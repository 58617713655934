const PenIcon = ({
  width = "100%",
  height = "100%",
  className = "",
}: {
  width?: string;
  height?: string;
  className?: string;
}) => {
  return (
    <svg
      className={className + " rounded-full fill-current"}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
    >
      <path d="M13.639 13.503a1.167 1.167 0 0 0-.475-.869 1.886 1.886 0 0 0-1.14-.309 2.21 2.21 0 0 0-.827.137 1.163 1.163 0 0 0-.514.376.89.89 0 0 0-.178.545c-.01.155.027.31.108.443.083.13.194.242.325.325.151.097.312.176.48.235.19.07.385.126.582.169l.85.203c.39.085.77.21 1.135.37.326.142.628.333.896.567.249.221.45.492.589.794.144.33.218.688.214 1.048a2.62 2.62 0 0 1-.44 1.503 2.806 2.806 0 0 1-1.257.983 5.03 5.03 0 0 1-1.973.349 5.18 5.18 0 0 1-1.992-.35 2.896 2.896 0 0 1-1.32-1.042 3.085 3.085 0 0 1-.497-1.71h1.935c.01.285.106.56.274.791.169.216.394.381.651.476.296.11.61.164.926.159a2.42 2.42 0 0 0 .884-.147c.228-.083.43-.225.584-.412a.947.947 0 0 0 .208-.604.79.79 0 0 0-.188-.536 1.455 1.455 0 0 0-.543-.369 5.648 5.648 0 0 0-.876-.277l-1.029-.258a4.314 4.314 0 0 1-1.889-.91 2.108 2.108 0 0 1-.687-1.667 2.47 2.47 0 0 1 .459-1.5 3.063 3.063 0 0 1 1.276-1.002 4.512 4.512 0 0 1 1.847-.36 4.382 4.382 0 0 1 1.84.36c.493.214.917.561 1.225 1.002.296.44.454.957.453 1.487h-1.916Zm7.465-3.163-3.047 11.32h-1.695l3.047-11.32h1.695Zm1.584 10.018a1.111 1.111 0 0 1-1.027-1.53c.056-.133.139-.253.243-.354a1.102 1.102 0 0 1 1.559 0 1.075 1.075 0 0 1 .178 1.332c-.1.165-.24.303-.405.402-.165.1-.355.152-.549.15Z" />
    </svg>
  );
};

export default PenIcon;
