const SekIcon = ({
  width = "100%",
  height = "100%",
  className = "",
}: {
  width?: string;
  height?: string;
  className?: string;
}) => {
  return (
    <svg
      className={className + " rounded-full fill-current"}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
    >
      <path d="m12.298 17.046 4.715 6.227h-2.607l-3.71-4.926-1.055 1.055v3.87H7.517V8.728H9.64v8.253h.184l4.347-4.617h2.542l-4.416 4.683Zm11.756-4.831a8.895 8.895 0 0 0-.472-.015 3.006 3.006 0 0 0-1.716.508c-.498.33-.87.82-1.055 1.388h-.113v-1.732h-2.053v10.909h2.125V16.61a2.372 2.372 0 0 1 1.267-2.142 2.778 2.778 0 0 1 1.339-.315c.218 0 .437.015.653.046.153.018.305.046.454.081v-2.03a5.364 5.364 0 0 0-.43-.035Z" />
    </svg>
  );
};

export default SekIcon;
