const TtdIcon = ({
  width = "100%",
  height = "100%",
  className = "",
}: {
  width?: string;
  height?: string;
  className?: string;
}) => {
  const regex = /\btext-([a-zA-Z0-9-]+)\b/;
  const matches = className.match(regex);
  const textClass = matches ? matches[0] : "text-white";
  return (
    <svg
      className={className + " rounded-full fill-current"}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
    >
      <g
        transform="translate(6,26.500000) scale(0.003800, -0.003800)"
        fill={textClass}
        stroke="none"
      >
        <path
          d="M4321 4222 c-72 -36 -76 -49 -79 -283 -3 -185 -5 -209 -19 -209 -9 0
-46 -7 -82 -16 -230 -57 -397 -215 -457 -435 -13 -46 -18 -100 -18 -194 0
-202 31 -292 137 -403 69 -71 193 -140 339 -187 l83 -27 -3 -389 -2 -388 -42
15 c-109 38 -192 116 -228 212 -12 31 -27 102 -34 159 -13 113 -23 131 -90
165 -52 27 -114 18 -161 -23 -50 -44 -59 -81 -45 -191 38 -323 175 -510 439
-598 49 -17 98 -30 109 -30 53 0 52 3 52 -221 l0 -210 38 -41 c33 -35 46 -41
88 -45 40 -4 55 0 83 19 74 50 76 57 79 282 l4 203 79 17 c127 27 229 85 330
185 144 144 199 288 199 519 -1 331 -131 489 -504 612 l-99 33 6 335 c4 184 9
337 11 339 3 3 29 -6 59 -20 101 -46 160 -136 176 -270 5 -43 17 -89 26 -103
53 -81 173 -86 244 -10 52 56 33 258 -38 402 -76 152 -230 259 -422 292 l-44
8 -5 216 c-5 214 -5 217 -30 244 -49 53 -119 67 -179 36z m-91 -1077 c0 -162
-2 -295 -5 -295 -14 0 -117 46 -161 73 -77 45 -94 82 -94 198 0 136 33 201
134 266 35 22 98 50 119 52 4 1 7 -132 7 -294z m368 -810 c166 -67 213 -124
220 -264 5 -98 -10 -163 -53 -231 -48 -76 -123 -128 -214 -149 l-41 -9 0 92
c0 190 13 586 20 586 4 0 34 -11 68 -25z"
        />
        <path
          d="M74 3701 c-55 -34 -74 -67 -74 -132 0 -51 4 -63 30 -92 45 -51 83
-57 379 -57 l260 0 3 -992 c2 -793 6 -999 16 -1023 16 -37 58 -82 94 -101 36
-19 141 -18 178 1 38 20 75 70 89 120 8 28 11 342 11 1017 l0 977 303 3 c268
3 306 5 335 21 100 55 95 208 -8 259 -32 17 -92 18 -810 18 -741 0 -776 -1
-806 -19z"
        />
        <path
          d="M1935 3706 c-17 -7 -43 -28 -58 -45 -23 -27 -27 -42 -27 -91 0 -52 4
-64 30 -93 45 -51 83 -57 379 -57 l260 0 3 -992 c3 -903 5 -996 20 -1028 17
-37 59 -80 95 -99 31 -16 139 -14 173 4 38 20 75 70 89 120 8 28 11 342 11
1017 l0 977 303 3 c268 3 306 5 335 21 100 55 95 208 -8 259 -32 17 -91 18
-805 18 -618 -1 -776 -3 -800 -14z"
        />
      </g>
    </svg>
  );
};

export default TtdIcon;
