export const timeZones = [
  { city: "Pago Pago", timeZone: "Pacific/Pago_Pago", offset: "(GMT-11:00)" },
  { city: "Niue", timeZone: "Pacific/Niue", offset: "(GMT-11:00)" },
  { city: "Midway", timeZone: "Pacific/Midway", offset: "(GMT-11:00)" },
  { city: "Tahiti", timeZone: "Pacific/Tahiti", offset: "(GMT-10:00)" },
  { city: "Adak", timeZone: "America/Adak", offset: "(GMT-10:00)" },
  { city: "Honolulu", timeZone: "Pacific/Honolulu", offset: "(GMT-10:00)" },
  { city: "Marquesas", timeZone: "Pacific/Marquesas", offset: "(GMT-09:30)" },
  { city: "Gambier", timeZone: "Pacific/Gambier", offset: "(GMT-09:00)" },
  { city: "Anchorage", timeZone: "America/Anchorage", offset: "(GMT-09:00)" },
  { city: "Juneau", timeZone: "America/Juneau", offset: "(GMT-09:00)" },
  { city: "Metlakatla", timeZone: "America/Metlakatla", offset: "(GMT-09:00)" },
  { city: "Nome", timeZone: "America/Nome", offset: "(GMT-09:00)" },
  { city: "Sitka", timeZone: "America/Sitka", offset: "(GMT-09:00)" },
  { city: "Yakutat", timeZone: "America/Yakutat", offset: "(GMT-09:00)" },
  { city: "Vancouver", timeZone: "America/Vancouver", offset: "(GMT-08:00)" },
  { city: "Tijuana", timeZone: "America/Tijuana", offset: "(GMT-08:00)" },
  { city: "Pitcairn", timeZone: "Pacific/Pitcairn", offset: "(GMT-08:00)" },
  {
    city: "Los Angeles",
    timeZone: "America/Los_Angeles",
    offset: "(GMT-08:00)",
  },
  {
    city: "Cambridge Bay",
    timeZone: "America/Cambridge_Bay",
    offset: "(GMT-07:00)",
  },
  { city: "Creston", timeZone: "America/Creston", offset: "(GMT-07:00)" },
  { city: "Dawson", timeZone: "America/Dawson", offset: "(GMT-07:00)" },
  {
    city: "Dawson Creek",
    timeZone: "America/Dawson_Creek",
    offset: "(GMT-07:00)",
  },
  { city: "Edmonton", timeZone: "America/Edmonton", offset: "(GMT-07:00)" },
  {
    city: "Fort Nelson",
    timeZone: "America/Fort_Nelson",
    offset: "(GMT-07:00)",
  },
  { city: "Inuvik", timeZone: "America/Inuvik", offset: "(GMT-07:00)" },
  { city: "Whitehorse", timeZone: "America/Whitehorse", offset: "(GMT-07:00)" },
  {
    city: "Yellowknife",
    timeZone: "America/Yellowknife",
    offset: "(GMT-07:00)",
  },
  { city: "Hermosillo", timeZone: "America/Hermosillo", offset: "(GMT-07:00)" },
  { city: "Mazatlan", timeZone: "America/Mazatlan", offset: "(GMT-07:00)" },
  { city: "Boise", timeZone: "America/Boise", offset: "(GMT-07:00)" },
  { city: "Denver", timeZone: "America/Denver", offset: "(GMT-07:00)" },
  { city: "Phoenix", timeZone: "America/Phoenix", offset: "(GMT-07:00)" },
  { city: "Belize", timeZone: "America/Belize", offset: "(GMT-06:00)" },
  {
    city: "Rankin Inlet",
    timeZone: "America/Rankin_Inlet",
    offset: "(GMT-06:00)",
  },
  { city: "Regina", timeZone: "America/Regina", offset: "(GMT-06:00)" },
  { city: "Resolute", timeZone: "America/Resolute", offset: "(GMT-06:00)" },
  {
    city: "Swift Current",
    timeZone: "America/Swift_Current",
    offset: "(GMT-06:00)",
  },
  { city: "Winnipeg", timeZone: "America/Winnipeg", offset: "(GMT-06:00)" },
  { city: "Costa Rica", timeZone: "America/Costa_Rica", offset: "(GMT-06:00)" },
  { city: "Galapagos", timeZone: "Pacific/Galapagos", offset: "(GMT-06:00)" },
  {
    city: "El Salvador",
    timeZone: "America/El_Salvador",
    offset: "(GMT-06:00)",
  },
  { city: "Guatemala", timeZone: "America/Guatemala", offset: "(GMT-06:00)" },
  {
    city: "Tegucigalpa",
    timeZone: "America/Tegucigalpa",
    offset: "(GMT-06:00)",
  },
  {
    city: "Bahia Banderas",
    timeZone: "America/Bahia_Banderas",
    offset: "(GMT-06:00)",
  },
  { city: "Chihuahua", timeZone: "America/Chihuahua", offset: "(GMT-07:00)" },
  { city: "Matamoros", timeZone: "America/Matamoros", offset: "(GMT-06:00)" },
  { city: "Merida", timeZone: "America/Merida", offset: "(GMT-06:00)" },
  {
    city: "Mexico City",
    timeZone: "America/Mexico_City",
    offset: "(GMT-06:00)",
  },
  { city: "Monterrey", timeZone: "America/Monterrey", offset: "(GMT-06:00)" },
  { city: "Ojinaga", timeZone: "America/Ojinaga", offset: "(GMT-07:00)" },
  { city: "Managua", timeZone: "America/Managua", offset: "(GMT-06:00)" },
  { city: "Chicago", timeZone: "America/Chicago", offset: "(GMT-06:00)" },
  { city: "Knox", timeZone: "America/Indiana/Knox", offset: "(GMT-06:00)" },
  {
    city: "Tell City",
    timeZone: "America/Indiana/Tell_City",
    offset: "(GMT-06:00)",
  },
  { city: "Menominee", timeZone: "America/Menominee", offset: "(GMT-06:00)" },
  {
    city: "Beulah",
    timeZone: "America/North_Dakota/Beulah",
    offset: "(GMT-06:00)",
  },
  {
    city: "Center",
    timeZone: "America/North_Dakota/Center",
    offset: "(GMT-06:00)",
  },
  {
    city: "New Salem",
    timeZone: "America/North_Dakota/New_Salem",
    offset: "(GMT-06:00)",
  },
  { city: "Nassau", timeZone: "America/Nassau", offset: "(GMT-05:00)" },
  { city: "Eirunepe", timeZone: "America/Eirunepe", offset: "(GMT-05:00)" },
  { city: "Rio Branco", timeZone: "America/Rio_Branco", offset: "(GMT-05:00)" },
  { city: "Atikokan", timeZone: "America/Atikokan", offset: "(GMT-05:00)" },
  { city: "Iqaluit", timeZone: "America/Iqaluit", offset: "(GMT-05:00)" },
  { city: "Toronto", timeZone: "America/Toronto", offset: "(GMT-05:00)" },
  { city: "Cayman", timeZone: "America/Cayman", offset: "(GMT-05:00)" },
  { city: "Easter", timeZone: "Pacific/Easter", offset: "(GMT-05:00)" },
  { city: "Bogota", timeZone: "America/Bogota", offset: "(GMT-05:00)" },
  { city: "Havana", timeZone: "America/Havana", offset: "(GMT-05:00)" },
  { city: "Guayaquil", timeZone: "America/Guayaquil", offset: "(GMT-05:00)" },
  {
    city: "Port-au-Prince",
    timeZone: "America/Port-au-Prince",
    offset: "(GMT-05:00)",
  },
  { city: "Jamaica", timeZone: "America/Jamaica", offset: "(GMT-05:00)" },
  { city: "Cancun", timeZone: "America/Cancun", offset: "(GMT-05:00)" },
  { city: "Panama", timeZone: "America/Panama", offset: "(GMT-05:00)" },
  { city: "Lima", timeZone: "America/Lima", offset: "(GMT-05:00)" },
  { city: "Grand Turk", timeZone: "America/Grand_Turk", offset: "(GMT-05:00)" },
  { city: "Detroit", timeZone: "America/Detroit", offset: "(GMT-05:00)" },
  {
    city: "Indianapolis",
    timeZone: "America/Indiana/Indianapolis",
    offset: "(GMT-05:00)",
  },
  {
    city: "Marengo",
    timeZone: "America/Indiana/Marengo",
    offset: "(GMT-05:00)",
  },
  {
    city: "Petersburg",
    timeZone: "America/Indiana/Petersburg",
    offset: "(GMT-05:00)",
  },
  { city: "Vevay", timeZone: "America/Indiana/Vevay", offset: "(GMT-05:00)" },
  {
    city: "Vincennes",
    timeZone: "America/Indiana/Vincennes",
    offset: "(GMT-05:00)",
  },
  {
    city: "Winamac",
    timeZone: "America/Indiana/Winamac",
    offset: "(GMT-05:00)",
  },
  {
    city: "Louisville",
    timeZone: "America/Kentucky/Louisville",
    offset: "(GMT-05:00)",
  },
  {
    city: "Monticello",
    timeZone: "America/Kentucky/Monticello",
    offset: "(GMT-05:00)",
  },
  { city: "New York", timeZone: "America/New_York", offset: "(GMT-05:00)" },
  { city: "Anguilla", timeZone: "America/Anguilla", offset: "(GMT-04:00)" },
  { city: "Antigua", timeZone: "America/Antigua", offset: "(GMT-04:00)" },
  { city: "Aruba", timeZone: "America/Aruba", offset: "(GMT-04:00)" },
  { city: "Barbados", timeZone: "America/Barbados", offset: "(GMT-04:00)" },
  { city: "Bermuda", timeZone: "Atlantic/Bermuda", offset: "(GMT-04:00)" },
  { city: "La Paz", timeZone: "America/La_Paz", offset: "(GMT-04:00)" },
  { city: "Kralendijk", timeZone: "America/Kralendijk", offset: "(GMT-04:00)" },
  { city: "Boa Vista", timeZone: "America/Boa_Vista", offset: "(GMT-04:00)" },
  {
    city: "Campo Grande",
    timeZone: "America/Campo_Grande",
    offset: "(GMT-04:00)",
  },
  { city: "Cuiaba", timeZone: "America/Cuiaba", offset: "(GMT-04:00)" },
  { city: "Manaus", timeZone: "America/Manaus", offset: "(GMT-04:00)" },
  {
    city: "Porto Velho",
    timeZone: "America/Porto_Velho",
    offset: "(GMT-04:00)",
  },
  {
    city: "Blanc-Sablon",
    timeZone: "America/Blanc-Sablon",
    offset: "(GMT-04:00)",
  },
  { city: "Glace Bay", timeZone: "America/Glace_Bay", offset: "(GMT-04:00)" },
  { city: "Goose Bay", timeZone: "America/Goose_Bay", offset: "(GMT-04:00)" },
  { city: "Halifax", timeZone: "America/Halifax", offset: "(GMT-04:00)" },
  { city: "Moncton", timeZone: "America/Moncton", offset: "(GMT-04:00)" },
  { city: "Curacao", timeZone: "America/Curacao", offset: "(GMT-04:00)" },
  { city: "Dominica", timeZone: "America/Dominica", offset: "(GMT-04:00)" },
  {
    city: "Santo Domingo",
    timeZone: "America/Santo_Domingo",
    offset: "(GMT-04:00)",
  },
  { city: "Thule", timeZone: "America/Thule", offset: "(GMT-04:00)" },
  { city: "Grenada", timeZone: "America/Grenada", offset: "(GMT-04:00)" },
  { city: "Guadeloupe", timeZone: "America/Guadeloupe", offset: "(GMT-04:00)" },
  { city: "Guyana", timeZone: "America/Guyana", offset: "(GMT-04:00)" },
  { city: "Martinique", timeZone: "America/Martinique", offset: "(GMT-04:00)" },
  { city: "Montserrat", timeZone: "America/Montserrat", offset: "(GMT-04:00)" },
  {
    city: "Puerto Rico",
    timeZone: "America/Puerto_Rico",
    offset: "(GMT-04:00)",
  },
  {
    city: "St Barthelemy",
    timeZone: "America/St_Barthelemy",
    offset: "(GMT-04:00)",
  },
  { city: "St Kitts", timeZone: "America/St_Kitts", offset: "(GMT-04:00)" },
  { city: "St Lucia", timeZone: "America/St_Lucia", offset: "(GMT-04:00)" },
  { city: "Marigot", timeZone: "America/Marigot", offset: "(GMT-04:00)" },
  { city: "St Vincent", timeZone: "America/St_Vincent", offset: "(GMT-04:00)" },
  {
    city: "Lower Princes",
    timeZone: "America/Lower_Princes",
    offset: "(GMT-04:00)",
  },
  {
    city: "Port of Spain",
    timeZone: "America/Port_of_Spain",
    offset: "(GMT-04:00)",
  },
  { city: "Caracas", timeZone: "America/Caracas", offset: "(GMT-04:00)" },
  { city: "Tortola", timeZone: "America/Tortola", offset: "(GMT-04:00)" },
  { city: "St Thomas", timeZone: "America/St_Thomas", offset: "(GMT-04:00)" },
  { city: "St Johns", timeZone: "America/St_Johns", offset: "(GMT-03:30)" },
  { city: "Palmer", timeZone: "Antarctica/Palmer", offset: "(GMT-03:00)" },
  { city: "Rothera", timeZone: "Antarctica/Rothera", offset: "(GMT-03:00)" },
  {
    city: "Buenos Aires",
    timeZone: "America/Argentina/Buenos_Aires",
    offset: "(GMT-03:00)",
  },
  {
    city: "Catamarca",
    timeZone: "America/Argentina/Catamarca",
    offset: "(GMT-03:00)",
  },
  {
    city: "Cordoba",
    timeZone: "America/Argentina/Cordoba",
    offset: "(GMT-03:00)",
  },
  { city: "Jujuy", timeZone: "America/Argentina/Jujuy", offset: "(GMT-03:00)" },
  {
    city: "La Rioja",
    timeZone: "America/Argentina/La_Rioja",
    offset: "(GMT-03:00)",
  },
  {
    city: "Mendoza",
    timeZone: "America/Argentina/Mendoza",
    offset: "(GMT-03:00)",
  },
  {
    city: "Rio Gallegos",
    timeZone: "America/Argentina/Rio_Gallegos",
    offset: "(GMT-03:00)",
  },
  { city: "Salta", timeZone: "America/Argentina/Salta", offset: "(GMT-03:00)" },
  {
    city: "San Juan",
    timeZone: "America/Argentina/San_Juan",
    offset: "(GMT-03:00)",
  },
  {
    city: "San Luis",
    timeZone: "America/Argentina/San_Luis",
    offset: "(GMT-03:00)",
  },
  {
    city: "Tucuman",
    timeZone: "America/Argentina/Tucuman",
    offset: "(GMT-03:00)",
  },
  {
    city: "Ushuaia",
    timeZone: "America/Argentina/Ushuaia",
    offset: "(GMT-03:00)",
  },
  { city: "Araguaina", timeZone: "America/Araguaina", offset: "(GMT-03:00)" },
  { city: "Bahia", timeZone: "America/Bahia", offset: "(GMT-03:00)" },
  { city: "Belem", timeZone: "America/Belem", offset: "(GMT-03:00)" },
  { city: "Fortaleza", timeZone: "America/Fortaleza", offset: "(GMT-03:00)" },
  { city: "Maceio", timeZone: "America/Maceio", offset: "(GMT-03:00)" },
  { city: "Recife", timeZone: "America/Recife", offset: "(GMT-03:00)" },
  { city: "Santarem", timeZone: "America/Santarem", offset: "(GMT-03:00)" },
  { city: "Sao Paulo", timeZone: "America/Sao_Paulo", offset: "(GMT-03:00)" },
  {
    city: "Punta Arenas",
    timeZone: "America/Punta_Arenas",
    offset: "(GMT-03:00)",
  },
  { city: "Santiago", timeZone: "America/Santiago", offset: "(GMT-03:00)" },
  { city: "Stanley", timeZone: "Atlantic/Stanley", offset: "(GMT-03:00)" },
  { city: "Cayenne", timeZone: "America/Cayenne", offset: "(GMT-03:00)" },
  { city: "Nuuk", timeZone: "America/Nuuk", offset: "(GMT-03:00)" },
  { city: "Asuncion", timeZone: "America/Asuncion", offset: "(GMT-03:00)" },
  { city: "Miquelon", timeZone: "America/Miquelon", offset: "(GMT-03:00)" },
  { city: "Paramaribo", timeZone: "America/Paramaribo", offset: "(GMT-03:00)" },
  { city: "Montevideo", timeZone: "America/Montevideo", offset: "(GMT-03:00)" },
  { city: "Noronha", timeZone: "America/Noronha", offset: "(GMT-02:00)" },
  {
    city: "South Georgia",
    timeZone: "Atlantic/South_Georgia",
    offset: "(GMT-02:00)",
  },
  {
    city: "Cape Verde",
    timeZone: "Atlantic/Cape_Verde",
    offset: "(GMT-01:00)",
  },
  {
    city: "Scoresbysund",
    timeZone: "America/Scoresbysund",
    offset: "(GMT-01:00)",
  },
  { city: "Azores", timeZone: "Atlantic/Azores", offset: "(GMT-01:00)" },
  { city: "Troll", timeZone: "Antarctica/Troll", offset: "(GMTZ)" },
  { city: "Ouagadougou", timeZone: "Africa/Ouagadougou", offset: "(GMTZ)" },
  { city: "Abidjan", timeZone: "Africa/Abidjan", offset: "(GMTZ)" },
  { city: "Faroe", timeZone: "Atlantic/Faroe", offset: "(GMTZ)" },
  { city: "Banjul", timeZone: "Africa/Banjul", offset: "(GMTZ)" },
  { city: "Accra", timeZone: "Africa/Accra", offset: "(GMTZ)" },
  { city: "Danmarkshavn", timeZone: "America/Danmarkshavn", offset: "(GMTZ)" },
  { city: "Guernsey", timeZone: "Europe/Guernsey", offset: "(GMTZ)" },
  { city: "Conakry", timeZone: "Africa/Conakry", offset: "(GMTZ)" },
  { city: "Bissau", timeZone: "Africa/Bissau", offset: "(GMTZ)" },
  { city: "Reykjavik", timeZone: "Atlantic/Reykjavik", offset: "(GMTZ)" },
  { city: "Dublin", timeZone: "Europe/Dublin", offset: "(GMTZ)" },
  { city: "Isle of Man", timeZone: "Europe/Isle_of_Man", offset: "(GMTZ)" },
  { city: "Jersey", timeZone: "Europe/Jersey", offset: "(GMTZ)" },
  { city: "Monrovia", timeZone: "Africa/Monrovia", offset: "(GMTZ)" },
  { city: "Bamako", timeZone: "Africa/Bamako", offset: "(GMTZ)" },
  { city: "Nouakchott", timeZone: "Africa/Nouakchott", offset: "(GMTZ)" },
  { city: "Madeira", timeZone: "Atlantic/Madeira", offset: "(GMTZ)" },
  { city: "Lisbon", timeZone: "Europe/Lisbon", offset: "(GMTZ)" },
  { city: "St Helena", timeZone: "Atlantic/St_Helena", offset: "(GMTZ)" },
  { city: "Sao Tome", timeZone: "Africa/Sao_Tome", offset: "(GMTZ)" },
  { city: "Dakar", timeZone: "Africa/Dakar", offset: "(GMTZ)" },
  { city: "Freetown", timeZone: "Africa/Freetown", offset: "(GMTZ)" },
  { city: "Canary", timeZone: "Atlantic/Canary", offset: "(GMTZ)" },
  { city: "Lome", timeZone: "Africa/Lome", offset: "(GMTZ)" },
  { city: "London", timeZone: "Europe/London", offset: "(GMTZ)" },
  { city: "Tirane", timeZone: "Europe/Tirane", offset: "(GMT+01:00)" },
  { city: "Algiers", timeZone: "Africa/Algiers", offset: "(GMT+01:00)" },
  { city: "Andorra", timeZone: "Europe/Andorra", offset: "(GMT+01:00)" },
  { city: "Luanda", timeZone: "Africa/Luanda", offset: "(GMT+01:00)" },
  { city: "Vienna", timeZone: "Europe/Vienna", offset: "(GMT+01:00)" },
  { city: "Brussels", timeZone: "Europe/Brussels", offset: "(GMT+01:00)" },
  { city: "Porto-Novo", timeZone: "Africa/Porto-Novo", offset: "(GMT+01:00)" },
  { city: "Sarajevo", timeZone: "Europe/Sarajevo", offset: "(GMT+01:00)" },
  { city: "Douala", timeZone: "Africa/Douala", offset: "(GMT+01:00)" },
  { city: "Bangui", timeZone: "Africa/Bangui", offset: "(GMT+01:00)" },
  { city: "Ndjamena", timeZone: "Africa/Ndjamena", offset: "(GMT+01:00)" },
  {
    city: "Brazzaville",
    timeZone: "Africa/Brazzaville",
    offset: "(GMT+01:00)",
  },
  { city: "Kinshasa", timeZone: "Africa/Kinshasa", offset: "(GMT+01:00)" },
  { city: "Zagreb", timeZone: "Europe/Zagreb", offset: "(GMT+01:00)" },
  { city: "Prague", timeZone: "Europe/Prague", offset: "(GMT+01:00)" },
  { city: "Copenhagen", timeZone: "Europe/Copenhagen", offset: "(GMT+01:00)" },
  { city: "Malabo", timeZone: "Africa/Malabo", offset: "(GMT+01:00)" },
  { city: "Paris", timeZone: "Europe/Paris", offset: "(GMT+01:00)" },
  { city: "Libreville", timeZone: "Africa/Libreville", offset: "(GMT+01:00)" },
  { city: "Berlin", timeZone: "Europe/Berlin", offset: "(GMT+01:00)" },
  { city: "Busingen", timeZone: "Europe/Busingen", offset: "(GMT+01:00)" },
  { city: "Gibraltar", timeZone: "Europe/Gibraltar", offset: "(GMT+01:00)" },
  { city: "Vatican", timeZone: "Europe/Vatican", offset: "(GMT+01:00)" },
  { city: "Budapest", timeZone: "Europe/Budapest", offset: "(GMT+01:00)" },
  { city: "Rome", timeZone: "Europe/Rome", offset: "(GMT+01:00)" },
  { city: "Vaduz", timeZone: "Europe/Vaduz", offset: "(GMT+01:00)" },
  { city: "Luxembourg", timeZone: "Europe/Luxembourg", offset: "(GMT+01:00)" },
  { city: "Skopje", timeZone: "Europe/Skopje", offset: "(GMT+01:00)" },
  { city: "Malta", timeZone: "Europe/Malta", offset: "(GMT+01:00)" },
  { city: "Monaco", timeZone: "Europe/Monaco", offset: "(GMT+01:00)" },
  { city: "Podgorica", timeZone: "Europe/Podgorica", offset: "(GMT+01:00)" },
  { city: "Casablanca", timeZone: "Africa/Casablanca", offset: "(GMT+01:00)" },
  { city: "El Aaiun", timeZone: "Africa/El_Aaiun", offset: "(GMT+01:00)" },
  { city: "Amsterdam", timeZone: "Europe/Amsterdam", offset: "(GMT+01:00)" },
  { city: "Niamey", timeZone: "Africa/Niamey", offset: "(GMT+01:00)" },
  { city: "Lagos", timeZone: "Africa/Lagos", offset: "(GMT+01:00)" },
  { city: "Oslo", timeZone: "Europe/Oslo", offset: "(GMT+01:00)" },
  { city: "Warsaw", timeZone: "Europe/Warsaw", offset: "(GMT+01:00)" },
  { city: "San Marino", timeZone: "Europe/San_Marino", offset: "(GMT+01:00)" },
  { city: "Belgrade", timeZone: "Europe/Belgrade", offset: "(GMT+01:00)" },
  { city: "Bratislava", timeZone: "Europe/Bratislava", offset: "(GMT+01:00)" },
  { city: "Ljubljana", timeZone: "Europe/Ljubljana", offset: "(GMT+01:00)" },
  { city: "Ceuta", timeZone: "Africa/Ceuta", offset: "(GMT+01:00)" },
  { city: "Madrid", timeZone: "Europe/Madrid", offset: "(GMT+01:00)" },
  {
    city: "Longyearbyen",
    timeZone: "Arctic/Longyearbyen",
    offset: "(GMT+01:00)",
  },
  { city: "Stockholm", timeZone: "Europe/Stockholm", offset: "(GMT+01:00)" },
  { city: "Zurich", timeZone: "Europe/Zurich", offset: "(GMT+01:00)" },
  { city: "Tunis", timeZone: "Africa/Tunis", offset: "(GMT+01:00)" },
  { city: "Gaborone", timeZone: "Africa/Gaborone", offset: "(GMT+02:00)" },
  { city: "Sofia", timeZone: "Europe/Sofia", offset: "(GMT+02:00)" },
  { city: "Bujumbura", timeZone: "Africa/Bujumbura", offset: "(GMT+02:00)" },
  { city: "Lubumbashi", timeZone: "Africa/Lubumbashi", offset: "(GMT+02:00)" },
  { city: "Famagusta", timeZone: "Asia/Famagusta", offset: "(GMT+02:00)" },
  { city: "Nicosia", timeZone: "Asia/Nicosia", offset: "(GMT+02:00)" },
  { city: "Cairo", timeZone: "Africa/Cairo", offset: "(GMT+02:00)" },
  { city: "Tallinn", timeZone: "Europe/Tallinn", offset: "(GMT+02:00)" },
  { city: "Helsinki", timeZone: "Europe/Helsinki", offset: "(GMT+02:00)" },
  { city: "Athens", timeZone: "Europe/Athens", offset: "(GMT+02:00)" },
  { city: "Jerusalem", timeZone: "Asia/Jerusalem", offset: "(GMT+02:00)" },
  { city: "Riga", timeZone: "Europe/Riga", offset: "(GMT+02:00)" },
  { city: "Beirut", timeZone: "Asia/Beirut", offset: "(GMT+02:00)" },
  { city: "Maseru", timeZone: "Africa/Maseru", offset: "(GMT+02:00)" },
  { city: "Tripoli", timeZone: "Africa/Tripoli", offset: "(GMT+02:00)" },
  { city: "Vilnius", timeZone: "Europe/Vilnius", offset: "(GMT+02:00)" },
  { city: "Blantyre", timeZone: "Africa/Blantyre", offset: "(GMT+02:00)" },
  { city: "Chisinau", timeZone: "Europe/Chisinau", offset: "(GMT+02:00)" },
  { city: "Maputo", timeZone: "Africa/Maputo", offset: "(GMT+02:00)" },
  { city: "Windhoek", timeZone: "Africa/Windhoek", offset: "(GMT+02:00)" },
  { city: "Gaza", timeZone: "Asia/Gaza", offset: "(GMT+02:00)" },
  { city: "Hebron", timeZone: "Asia/Hebron", offset: "(GMT+02:00)" },
  { city: "Bucharest", timeZone: "Europe/Bucharest", offset: "(GMT+02:00)" },
  {
    city: "Kaliningrad",
    timeZone: "Europe/Kaliningrad",
    offset: "(GMT+02:00)",
  },
  { city: "Kigali", timeZone: "Africa/Kigali", offset: "(GMT+02:00)" },
  {
    city: "Johannesburg",
    timeZone: "Africa/Johannesburg",
    offset: "(GMT+02:00)",
  },
  { city: "Juba", timeZone: "Africa/Juba", offset: "(GMT+02:00)" },
  { city: "Khartoum", timeZone: "Africa/Khartoum", offset: "(GMT+02:00)" },
  { city: "Mbabane", timeZone: "Africa/Mbabane", offset: "(GMT+02:00)" },
  { city: "Kyiv", timeZone: "Europe/Kyiv", offset: "(GMT+02:00)" },
  { city: "Lusaka", timeZone: "Africa/Lusaka", offset: "(GMT+02:00)" },
  { city: "Harare", timeZone: "Africa/Harare", offset: "(GMT+02:00)" },
  { city: "Mariehamn", timeZone: "Europe/Mariehamn", offset: "(GMT+02:00)" },
  { city: "Syowa", timeZone: "Antarctica/Syowa", offset: "(GMT+03:00)" },
  { city: "Bahrain", timeZone: "Asia/Bahrain", offset: "(GMT+03:00)" },
  { city: "Minsk", timeZone: "Europe/Minsk", offset: "(GMT+03:00)" },
  { city: "Comoro", timeZone: "Indian/Comoro", offset: "(GMT+03:00)" },
  { city: "Djibouti", timeZone: "Africa/Djibouti", offset: "(GMT+03:00)" },
  { city: "Asmara", timeZone: "Africa/Asmara", offset: "(GMT+03:00)" },
  {
    city: "Addis Ababa",
    timeZone: "Africa/Addis_Ababa",
    offset: "(GMT+03:00)",
  },
  { city: "Baghdad", timeZone: "Asia/Baghdad", offset: "(GMT+03:00)" },
  { city: "Amman", timeZone: "Asia/Amman", offset: "(GMT+03:00)" },
  { city: "Nairobi", timeZone: "Africa/Nairobi", offset: "(GMT+03:00)" },
  { city: "Kuwait", timeZone: "Asia/Kuwait", offset: "(GMT+03:00)" },
  {
    city: "Antananarivo",
    timeZone: "Indian/Antananarivo",
    offset: "(GMT+03:00)",
  },
  { city: "Mayotte", timeZone: "Indian/Mayotte", offset: "(GMT+03:00)" },
  { city: "Qatar", timeZone: "Asia/Qatar", offset: "(GMT+03:00)" },
  { city: "Kirov", timeZone: "Europe/Kirov", offset: "(GMT+03:00)" },
  { city: "Moscow", timeZone: "Europe/Moscow", offset: "(GMT+03:00)" },
  { city: "Volgograd", timeZone: "Europe/Volgograd", offset: "(GMT+03:00)" },
  { city: "Riyadh", timeZone: "Asia/Riyadh", offset: "(GMT+03:00)" },
  { city: "Mogadishu", timeZone: "Africa/Mogadishu", offset: "(GMT+03:00)" },
  { city: "Damascus", timeZone: "Asia/Damascus", offset: "(GMT+03:00)" },
  {
    city: "Dar es Salaam",
    timeZone: "Africa/Dar_es_Salaam",
    offset: "(GMT+03:00)",
  },
  { city: "Istanbul", timeZone: "Europe/Istanbul", offset: "(GMT+03:00)" },
  { city: "Kampala", timeZone: "Africa/Kampala", offset: "(GMT+03:00)" },
  { city: "Simferopol", timeZone: "Europe/Simferopol", offset: "(GMT+03:00)" },
  { city: "Aden", timeZone: "Asia/Aden", offset: "(GMT+03:00)" },
  { city: "Tehran", timeZone: "Asia/Tehran", offset: "(GMT+03:30)" },
  { city: "Yerevan", timeZone: "Asia/Yerevan", offset: "(GMT+04:00)" },
  { city: "Baku", timeZone: "Asia/Baku", offset: "(GMT+04:00)" },
  { city: "Tbilisi", timeZone: "Asia/Tbilisi", offset: "(GMT+04:00)" },
  { city: "Mauritius", timeZone: "Indian/Mauritius", offset: "(GMT+04:00)" },
  { city: "Muscat", timeZone: "Asia/Muscat", offset: "(GMT+04:00)" },
  { city: "Astrakhan", timeZone: "Europe/Astrakhan", offset: "(GMT+04:00)" },
  { city: "Samara", timeZone: "Europe/Samara", offset: "(GMT+04:00)" },
  { city: "Saratov", timeZone: "Europe/Saratov", offset: "(GMT+04:00)" },
  { city: "Ulyanovsk", timeZone: "Europe/Ulyanovsk", offset: "(GMT+04:00)" },
  { city: "Reunion", timeZone: "Indian/Reunion", offset: "(GMT+04:00)" },
  { city: "Mahe", timeZone: "Indian/Mahe", offset: "(GMT+04:00)" },
  { city: "Dubai", timeZone: "Asia/Dubai", offset: "(GMT+04:00)" },
  { city: "Kabul", timeZone: "Asia/Kabul", offset: "(GMT+04:30)" },
  { city: "Mawson", timeZone: "Antarctica/Mawson", offset: "(GMT+05:00)" },
  { city: "Kerguelen", timeZone: "Indian/Kerguelen", offset: "(GMT+05:00)" },
  { city: "Aqtau", timeZone: "Asia/Aqtau", offset: "(GMT+05:00)" },
  { city: "Aqtobe", timeZone: "Asia/Aqtobe", offset: "(GMT+05:00)" },
  { city: "Atyrau", timeZone: "Asia/Atyrau", offset: "(GMT+05:00)" },
  { city: "Oral", timeZone: "Asia/Oral", offset: "(GMT+05:00)" },
  { city: "Qyzylorda", timeZone: "Asia/Qyzylorda", offset: "(GMT+05:00)" },
  { city: "Maldives", timeZone: "Indian/Maldives", offset: "(GMT+05:00)" },
  { city: "Karachi", timeZone: "Asia/Karachi", offset: "(GMT+05:00)" },
  {
    city: "Yekaterinburg",
    timeZone: "Asia/Yekaterinburg",
    offset: "(GMT+05:00)",
  },
  { city: "Dushanbe", timeZone: "Asia/Dushanbe", offset: "(GMT+05:00)" },
  { city: "Ashgabat", timeZone: "Asia/Ashgabat", offset: "(GMT+05:00)" },
  { city: "Samarkand", timeZone: "Asia/Samarkand", offset: "(GMT+05:00)" },
  { city: "Tashkent", timeZone: "Asia/Tashkent", offset: "(GMT+05:00)" },
  { city: "Kolkata", timeZone: "Asia/Kolkata", offset: "(GMT+05:30)" },
  { city: "Colombo", timeZone: "Asia/Colombo", offset: "(GMT+05:30)" },
  { city: "Kathmandu", timeZone: "Asia/Kathmandu", offset: "(GMT+05:45)" },
  { city: "Vostok", timeZone: "Antarctica/Vostok", offset: "(GMT+06:00)" },
  { city: "Dhaka", timeZone: "Asia/Dhaka", offset: "(GMT+06:00)" },
  { city: "Thimphu", timeZone: "Asia/Thimphu", offset: "(GMT+06:00)" },
  { city: "Chagos", timeZone: "Indian/Chagos", offset: "(GMT+06:00)" },
  { city: "Urumqi", timeZone: "Asia/Urumqi", offset: "(GMT+06:00)" },
  { city: "Almaty", timeZone: "Asia/Almaty", offset: "(GMT+06:00)" },
  { city: "Qostanay", timeZone: "Asia/Qostanay", offset: "(GMT+06:00)" },
  { city: "Bishkek", timeZone: "Asia/Bishkek", offset: "(GMT+06:00)" },
  { city: "Omsk", timeZone: "Asia/Omsk", offset: "(GMT+06:00)" },
  { city: "Cocos", timeZone: "Indian/Cocos", offset: "(GMT+06:30)" },
  { city: "Yangon", timeZone: "Asia/Yangon", offset: "(GMT+06:30)" },
  { city: "Davis", timeZone: "Antarctica/Davis", offset: "(GMT+07:00)" },
  { city: "Phnom Penh", timeZone: "Asia/Phnom_Penh", offset: "(GMT+07:00)" },
  { city: "Christmas", timeZone: "Indian/Christmas", offset: "(GMT+07:00)" },
  { city: "Jakarta", timeZone: "Asia/Jakarta", offset: "(GMT+07:00)" },
  { city: "Pontianak", timeZone: "Asia/Pontianak", offset: "(GMT+07:00)" },
  { city: "Vientiane", timeZone: "Asia/Vientiane", offset: "(GMT+07:00)" },
  { city: "Hovd", timeZone: "Asia/Hovd", offset: "(GMT+07:00)" },
  { city: "Barnaul", timeZone: "Asia/Barnaul", offset: "(GMT+07:00)" },
  { city: "Krasnoyarsk", timeZone: "Asia/Krasnoyarsk", offset: "(GMT+07:00)" },
  {
    city: "Novokuznetsk",
    timeZone: "Asia/Novokuznetsk",
    offset: "(GMT+07:00)",
  },
  { city: "Novosibirsk", timeZone: "Asia/Novosibirsk", offset: "(GMT+07:00)" },
  { city: "Tomsk", timeZone: "Asia/Tomsk", offset: "(GMT+07:00)" },
  { city: "Bangkok", timeZone: "Asia/Bangkok", offset: "(GMT+07:00)" },
  { city: "Ho Chi Minh", timeZone: "Asia/Ho_Chi_Minh", offset: "(GMT+07:00)" },
  { city: "Perth", timeZone: "Australia/Perth", offset: "(GMT+08:00)" },
  { city: "Brunei", timeZone: "Asia/Brunei", offset: "(GMT+08:00)" },
  { city: "Shanghai", timeZone: "Asia/Shanghai", offset: "(GMT+08:00)" },
  { city: "Hong Kong", timeZone: "Asia/Hong_Kong", offset: "(GMT+08:00)" },
  { city: "Makassar", timeZone: "Asia/Makassar", offset: "(GMT+08:00)" },
  { city: "Macau", timeZone: "Asia/Macau", offset: "(GMT+08:00)" },
  {
    city: "Kuala Lumpur",
    timeZone: "Asia/Kuala_Lumpur",
    offset: "(GMT+08:00)",
  },
  { city: "Kuching", timeZone: "Asia/Kuching", offset: "(GMT+08:00)" },
  { city: "Choibalsan", timeZone: "Asia/Choibalsan", offset: "(GMT+08:00)" },
  { city: "Ulaanbaatar", timeZone: "Asia/Ulaanbaatar", offset: "(GMT+08:00)" },
  { city: "Manila", timeZone: "Asia/Manila", offset: "(GMT+08:00)" },
  { city: "Irkutsk", timeZone: "Asia/Irkutsk", offset: "(GMT+08:00)" },
  { city: "Singapore", timeZone: "Asia/Singapore", offset: "(GMT+08:00)" },
  { city: "Taipei", timeZone: "Asia/Taipei", offset: "(GMT+08:00)" },
  { city: "Eucla", timeZone: "Australia/Eucla", offset: "(GMT+08:45)" },
  { city: "Jayapura", timeZone: "Asia/Jayapura", offset: "(GMT+09:00)" },
  { city: "Tokyo", timeZone: "Asia/Tokyo", offset: "(GMT+09:00)" },
  { city: "Pyongyang", timeZone: "Asia/Pyongyang", offset: "(GMT+09:00)" },
  { city: "Seoul", timeZone: "Asia/Seoul", offset: "(GMT+09:00)" },
  { city: "Palau", timeZone: "Pacific/Palau", offset: "(GMT+09:00)" },
  { city: "Chita", timeZone: "Asia/Chita", offset: "(GMT+09:00)" },
  { city: "Khandyga", timeZone: "Asia/Khandyga", offset: "(GMT+09:00)" },
  { city: "Yakutsk", timeZone: "Asia/Yakutsk", offset: "(GMT+09:00)" },
  { city: "Dili", timeZone: "Asia/Dili", offset: "(GMT+09:00)" },
  { city: "Darwin", timeZone: "Australia/Darwin", offset: "(GMT+09:30)" },
  {
    city: "DumontDUrville",
    timeZone: "Antarctica/DumontDUrville",
    offset: "(GMT+10:00)",
  },
  { city: "Brisbane", timeZone: "Australia/Brisbane", offset: "(GMT+10:00)" },
  { city: "Lindeman", timeZone: "Australia/Lindeman", offset: "(GMT+10:00)" },
  { city: "Guam", timeZone: "Pacific/Guam", offset: "(GMT+10:00)" },
  { city: "Chuuk", timeZone: "Pacific/Chuuk", offset: "(GMT+10:00)" },
  { city: "Saipan", timeZone: "Pacific/Saipan", offset: "(GMT+10:00)" },
  {
    city: "Port Moresby",
    timeZone: "Pacific/Port_Moresby",
    offset: "(GMT+10:00)",
  },
  { city: "Ust-Nera", timeZone: "Asia/Ust-Nera", offset: "(GMT+10:00)" },
  { city: "Vladivostok", timeZone: "Asia/Vladivostok", offset: "(GMT+10:00)" },
  { city: "Adelaide", timeZone: "Australia/Adelaide", offset: "(GMT+10:30)" },
  {
    city: "Broken Hill",
    timeZone: "Australia/Broken_Hill",
    offset: "(GMT+10:30)",
  },
  { city: "Casey", timeZone: "Antarctica/Casey", offset: "(GMT+11:00)" },
  {
    city: "Macquarie",
    timeZone: "Antarctica/Macquarie",
    offset: "(GMT+11:00)",
  },
  { city: "Hobart", timeZone: "Australia/Hobart", offset: "(GMT+11:00)" },
  { city: "Lord Howe", timeZone: "Australia/Lord_Howe", offset: "(GMT+11:00)" },
  { city: "Melbourne", timeZone: "Australia/Melbourne", offset: "(GMT+11:00)" },
  { city: "Sydney", timeZone: "Australia/Sydney", offset: "(GMT+11:00)" },
  { city: "Kosrae", timeZone: "Pacific/Kosrae", offset: "(GMT+11:00)" },
  { city: "Pohnpei", timeZone: "Pacific/Pohnpei", offset: "(GMT+11:00)" },
  { city: "Noumea", timeZone: "Pacific/Noumea", offset: "(GMT+11:00)" },
  {
    city: "Bougainville",
    timeZone: "Pacific/Bougainville",
    offset: "(GMT+11:00)",
  },
  { city: "Magadan", timeZone: "Asia/Magadan", offset: "(GMT+11:00)" },
  { city: "Sakhalin", timeZone: "Asia/Sakhalin", offset: "(GMT+11:00)" },
  {
    city: "Srednekolymsk",
    timeZone: "Asia/Srednekolymsk",
    offset: "(GMT+11:00)",
  },
  {
    city: "Guadalcanal",
    timeZone: "Pacific/Guadalcanal",
    offset: "(GMT+11:00)",
  },
  { city: "Efate", timeZone: "Pacific/Efate", offset: "(GMT+11:00)" },
  { city: "Fiji", timeZone: "Pacific/Fiji", offset: "(GMT+13:00)" },
  { city: "Tarawa", timeZone: "Pacific/Tarawa", offset: "(GMT+12:00)" },
  { city: "Kwajalein", timeZone: "Pacific/Kwajalein", offset: "(GMT+12:00)" },
  { city: "Majuro", timeZone: "Pacific/Majuro", offset: "(GMT+12:00)" },
  { city: "Nauru", timeZone: "Pacific/Nauru", offset: "(GMT+12:00)" },
  { city: "Norfolk", timeZone: "Pacific/Norfolk", offset: "(GMT+12:00)" },
  { city: "Anadyr", timeZone: "Asia/Anadyr", offset: "(GMT+12:00)" },
  { city: "Kamchatka", timeZone: "Asia/Kamchatka", offset: "(GMT+12:00)" },
  { city: "Funafuti", timeZone: "Pacific/Funafuti", offset: "(GMT+12:00)" },
  { city: "Wake", timeZone: "Pacific/Wake", offset: "(GMT+12:00)" },
  { city: "Wallis", timeZone: "Pacific/Wallis", offset: "(GMT+12:00)" },
  { city: "McMurdo", timeZone: "Antarctica/McMurdo", offset: "(GMT+13:00)" },
  { city: "Kanton", timeZone: "Pacific/Kanton", offset: "(GMT+13:00)" },
  { city: "Auckland", timeZone: "Pacific/Auckland", offset: "(GMT+13:00)" },
  { city: "Apia", timeZone: "Pacific/Apia", offset: "(GMT+13:00)" },
  { city: "Fakaofo", timeZone: "Pacific/Fakaofo", offset: "(GMT+13:00)" },
  { city: "Tongatapu", timeZone: "Pacific/Tongatapu", offset: "(GMT+13:00)" },
  { city: "Chatham", timeZone: "Pacific/Chatham", offset: "(GMT+13:45)" },
  { city: "Kiritimati", timeZone: "Pacific/Kiritimati", offset: "(GMT+14:00)" },
];
