const GbpIcon = ({
  width = "100%",
  height = "100%",
  className = "",
}: {
  width?: string;
  height?: string;
  className?: string;
}) => {
  return (
    <svg
      className={className + " rounded-full fill-current"}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
    >
      <path d="M17.936 17.676h-7.01v-1.89h7.01v1.89Zm2.982 5.696h-9.942v-1.89h9.942v1.89ZM14.492 13.13l.226 6.13a4.012 4.012 0 0 1-.234 1.637 2.25 2.25 0 0 1-1.001 1.147l-1.819-.561a.693.693 0 0 0 .59-.39c.145-.271.239-.566.277-.87.045-.3.064-.603.056-.906l-.205-6.187a4.36 4.36 0 0 1 .557-2.492 3.939 3.939 0 0 1 1.637-1.506 5.18 5.18 0 0 1 4.095-.156 4.154 4.154 0 0 1 1.42.948c.377.386.66.854.827 1.367.167.52.201 1.075.1 1.612l-2.053-.326a1.78 1.78 0 0 0-.22-1.158 1.828 1.828 0 0 0-.802-.696 2.579 2.579 0 0 0-1.087-.234c-.4 0-.794.097-1.147.284a2.18 2.18 0 0 0-.88.866 2.95 2.95 0 0 0-.338 1.491Z" />
    </svg>
  );
};

export default GbpIcon;
