const HkdIcon = ({
  width = "100%",
  height = "100%",
  className = "",
}: {
  width?: string;
  height?: string;
  className?: string;
}) => {
  return (
    <svg
      className={className + " rounded-full fill-current"}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
    >
      <path d="M3.12 20.727v-9.454h1.999v3.9h4.058v-3.9h1.994v9.454H9.177v-3.905H5.12v3.905h-2Zm9.699 0v-9.454h1.999v4.169h.125l3.402-4.17h2.396l-3.508 4.234 3.55 5.221H18.39L15.8 16.84l-.983 1.2v2.687h-2Zm14.015-6.735a1.169 1.169 0 0 0-.474-.868 1.886 1.886 0 0 0-1.14-.31 2.21 2.21 0 0 0-.827.137 1.163 1.163 0 0 0-.515.376.889.889 0 0 0-.177.545c-.01.155.027.31.108.443.083.131.194.242.325.325.15.097.312.176.48.236.19.069.385.125.582.168l.85.203c.39.086.77.21 1.135.37.326.142.628.333.896.568.249.22.45.491.588.794.145.33.219.687.215 1.048a2.62 2.62 0 0 1-.441 1.503 2.805 2.805 0 0 1-1.256.982 5.028 5.028 0 0 1-1.974.35 5.182 5.182 0 0 1-1.99-.351 2.896 2.896 0 0 1-1.321-1.041 3.086 3.086 0 0 1-.497-1.71h1.934c.01.284.106.56.275.79.169.217.394.381.651.476.296.11.61.164.926.16.301.006.6-.044.884-.148.228-.082.43-.224.584-.411a.947.947 0 0 0 .208-.605.79.79 0 0 0-.188-.535 1.453 1.453 0 0 0-.544-.37 5.644 5.644 0 0 0-.875-.277l-1.029-.258a4.314 4.314 0 0 1-1.889-.91 2.11 2.11 0 0 1-.687-1.666 2.47 2.47 0 0 1 .459-1.5 3.063 3.063 0 0 1 1.276-1.002 4.511 4.511 0 0 1 1.847-.36 4.38 4.38 0 0 1 1.84.36c.493.214.917.56 1.225 1.001.296.44.454.957.453 1.487h-1.917Zm-2.215 7.917.111-11.818h1.089l-.11 11.818h-1.09Z" />
    </svg>
  );
};

export default HkdIcon;
