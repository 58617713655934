const CrcIcon = ({
  width = "100%",
  height = "100%",
  className = "",
}: {
  width?: string;
  height?: string;
  className?: string;
}) => {
  const regex = /\btext-([a-zA-Z0-9-]+)\b/;
  const matches = className.match(regex);
  const textClass = matches ? matches[0] : "text-white";
  return (
    <svg
      className={className + " rounded-full fill-current"}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
    >
      <g
        transform="translate(8,26.000000) scale(0.004000, -0.004000)"
        fill={textClass}
        stroke="none"
      >
        <path
          d="M1880 5078 c-7 -24 -33 -122 -60 -218 -27 -96 -53 -194 -59 -216
l-11 -41 -108 -12 c-213 -24 -436 -86 -626 -175 -535 -250 -875 -741 -983
-1416 -23 -146 -26 -642 -5 -785 64 -423 206 -799 404 -1067 33 -46 110 -131
170 -191 l110 -107 -115 -418 c-64 -229 -112 -420 -108 -425 4 -4 90 -6 192
-5 l184 3 94 326 93 327 82 -29 c44 -16 107 -36 139 -45 38 -10 57 -20 54 -28
-12 -32 -147 -528 -147 -541 0 -13 29 -15 190 -15 l189 0 10 38 c6 20 39 136
74 257 l63 220 199 6 c211 6 339 24 485 66 578 167 948 603 1054 1243 8 52 17
133 18 180 l3 85 -175 3 c-96 1 -229 -1 -294 -5 l-120 -8 -17 -105 c-53 -327
-163 -568 -321 -703 -162 -138 -359 -215 -617 -243 -63 -6 -64 -6 -59 17 14
69 774 2699 780 2699 11 0 82 -108 120 -185 44 -87 82 -197 102 -293 8 -41 16
-76 19 -78 7 -7 590 -33 600 -27 17 10 -1 202 -28 311 -84 345 -303 643 -625
853 l-24 16 108 377 c66 230 104 382 99 387 -5 5 -101 8 -219 7 l-209 -3 -81
-293 c-44 -160 -83 -294 -85 -296 -2 -2 -44 7 -94 20 -49 12 -100 23 -112 23
-12 1 -24 5 -28 11 -3 5 26 118 65 251 38 133 70 253 70 266 l0 23 -215 0
-214 0 -11 -42z m300 -1029 c42 -11 81 -23 88 -28 10 -6 -82 -352 -391 -1470
-222 -804 -406 -1464 -409 -1467 -12 -11 -258 97 -258 114 0 4 185 654 412
1445 l412 1437 36 -6 c19 -3 69 -15 110 -25z m-584 4 c-3 -10 -164 -593 -357
-1296 l-353 -1278 -24 38 c-151 244 -227 543 -248 968 -15 330 60 745 180 996
134 281 385 483 699 564 112 29 110 29 103 8z"
        />
      </g>
    </svg>
  );
};

export default CrcIcon;
