import {
  XIcon,
  InformationCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/outline";
import { DocumentDuplicateIcon } from "@heroicons/react/solid";
import toast from "react-hot-toast";
import { Transition } from "@headlessui/react";
import { Fragment } from "react";
import { Toast } from "react-hot-toast/dist/core/types";
import Button from "components/Button/Button";
import Link from "next/link";
import { useState } from "react";
import { translate } from "utils";

const COPY_TIMEOUT = 3000;

const CustomToast = ({
  t,
  content,
  icon = "info",
  isButton,
}: {
  t: Toast;
  content: { title: string; body?: string };
  icon?: "error" | "info";
  isButton?: boolean;
}) => {
  const [isCopying, setIsCopying] = useState(false);
  const handleCopy = () => {
    setIsCopying(true);
    window.navigator.clipboard.writeText(content.body);
    setTimeout(() => {
      setIsCopying(false);
    }, COPY_TIMEOUT);
  };
  return (
    <Transition
      show={t.visible}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        onClick={() => toast.dismiss(t.id)}
        className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
      >
        <div className="p-4">
          <div className="flex items-start">
            <div className="flex-shrink-0">
              {icon === "error" ? (
                <ExclamationCircleIcon
                  className="h-6 w-6 text-red-400"
                  aria-hidden="true"
                />
              ) : (
                <InformationCircleIcon
                  className="h-6 w-6 text-blue-400"
                  aria-hidden="true"
                />
              )}
            </div>
            <div className="ml-3 w-0 flex-1 pt-0.5">
              <p className="text-sm font-medium text-gray-900">
                {content.title}
              </p>
              <p className="mt-1 text-sm text-gray-500">{content.body}</p>
            </div>
            <div className="ml-4 flex-shrink-0 flex">
              {isButton ? (
                <>
                  {!isCopying ? (
                    <DocumentDuplicateIcon
                      className=" w-5 h-5 text-gray-500 mr-2 cursor-pointer"
                      onClick={handleCopy}
                    />
                  ) : (
                    <div className="mr-2 cursor-pointer mt-1">
                      <img src="/img/copy_check.svg" alt="Text is copying" />
                    </div>
                  )}
                </>
              ) : null}
              <button
                className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none"
                onClick={() => toast.dismiss(t.id)}
              >
                <span className="sr-only">Close</span>
                <XIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
          {isButton ? (
            <Link href="https://t.me/waylightsupportbot">
              <a target="_blank" rel="noreferrer">
                <div className="mt-3 ml-8">
                  <Button type="primary">
                    {translate("buttonRequest")}
                    <img src="/img/telegram.svg" alt="" className="ml-2" />
                  </Button>
                </div>
              </a>
            </Link>
          ) : null}
        </div>
      </div>
    </Transition>
  );
};

export default CustomToast;
