const XLSIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 36 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.57141 0H24.4195L36 12.8665V43.0221C36 44.6067 34.8481 45.8902 33.4286 45.8902H2.57141C1.15194 45.8902 0 44.6067 0 43.0221V2.86811C0 1.28352 1.15207 0 2.57141 0Z"
      fill="#34D399"
    />
    <path
      d="M7.43359 28.1914C7.43359 28.6836 7.82617 29.0586 8.3418 29.0586C8.71094 29.0586 8.9043 28.9121 9.31445 28.332L10.709 26.3164H10.7969L12.2676 28.332C12.7246 28.9355 12.8887 29.0586 13.2461 29.0586C13.7676 29.0586 14.166 28.6836 14.166 28.1914C14.166 27.9746 14.0781 27.7578 13.8906 27.5176L11.9395 25.0273L13.8555 22.4727C14.0488 22.2207 14.1367 22.0098 14.1367 21.8047C14.1367 21.3125 13.7441 20.9316 13.2227 20.9316C12.8418 20.9316 12.625 21.0957 12.2559 21.6582L10.873 23.7617H10.7852L9.32031 21.6582C8.89844 21.0723 8.71094 20.9316 8.3418 20.9316C7.82031 20.9316 7.42188 21.3066 7.42188 21.8047C7.42188 22.0215 7.50977 22.2383 7.69727 22.4727L9.58398 24.9746L7.70898 27.5234C7.52148 27.7754 7.43359 27.9863 7.43359 28.1914ZM20.4883 28.1914C20.4883 27.7168 20.1484 27.3828 19.6621 27.3828H17.1719V21.9863C17.1719 21.3242 16.8203 20.9434 16.1992 20.9434C15.5781 20.9434 15.2324 21.3184 15.2324 21.9863V27.8926C15.2324 28.6133 15.625 29 16.3574 29H19.6621C20.1484 29 20.4883 28.666 20.4883 28.1914ZM20.9688 27.3535C20.9688 27.6816 21.1562 28.0215 21.4961 28.3438C22.041 28.8594 23.0195 29.1758 24.1211 29.1758C26.125 29.1758 27.4082 28.1621 27.4082 26.5859C27.4082 25.373 26.6875 24.6406 25.1992 24.3418L24.0859 24.1016C23.3418 23.9551 23.002 23.6445 23.002 23.2051C23.002 22.7012 23.4473 22.3789 24.1562 22.3789C24.7598 22.3789 25.2344 22.6133 25.6445 23.0645C25.8906 23.2988 26.1426 23.4102 26.3945 23.4102C26.8457 23.4102 27.1504 23.1172 27.1504 22.6895C27.1504 22.332 26.9688 21.9805 26.623 21.6641C26.084 21.1543 25.1582 20.8555 24.1328 20.8555C22.2578 20.8555 21.0625 21.8281 21.0625 23.3633C21.0625 24.5879 21.7715 25.332 23.2129 25.6367L24.3145 25.8887C25.1582 26.0703 25.5098 26.3457 25.5098 26.791C25.5098 27.3418 25.0586 27.6465 24.2207 27.6465C23.6289 27.6465 23.0488 27.4004 22.5684 26.9492C22.2461 26.6621 22.0059 26.5566 21.7246 26.5566C21.3086 26.5566 20.9688 26.8848 20.9688 27.3535Z"
      fill="white"
    />
    <path
      d="M35.958 12.9116H26.9952C25.5758 12.9116 24.4238 11.6268 24.4238 10.0435V0.0336914L35.958 12.9116Z"
      fill="#047857"
    />
  </svg>
);

export default XLSIcon;
