const ThbIcon = ({
  width = "100%",
  height = "100%",
  className = "",
}: {
  width?: string;
  height?: string;
  className?: string;
}) => {
  const regex = /\btext-([a-zA-Z0-9-]+)\b/;
  const matches = className.match(regex);
  const textClass = matches ? matches[0] : "text-white";
  return (
    <svg
      className={className + " rounded-full fill-current"}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
    >
      <g
        transform="translate(8,24.500000) scale(0.003500, -0.003500)"
        fill={textClass}
        stroke="none"
      >
        <path
          d="M2190 4970 l0 -150 -405 0 -405 0 0 -2260 0 -2260 405 0 405 0 0
-150 0 -150 160 0 160 0 0 150 0 150 68 0 c158 1 375 51 507 118 156 78 347
246 440 387 104 157 179 345 206 519 7 41 9 138 6 231 -5 179 -26 283 -91 441
-98 241 -325 488 -553 603 l-43 21 111 107 c193 187 300 381 348 636 27 142
33 246 21 368 -42 413 -271 764 -621 953 -103 55 -194 88 -312 113 l-87 18 0
152 0 153 -160 0 -160 0 0 -150z m0 -1360 l0 -890 -250 0 -250 0 0 890 0 890
250 0 250 0 0 -890z m429 849 c170 -53 332 -174 433 -322 123 -181 181 -410
158 -619 -13 -119 -34 -193 -80 -294 -87 -190 -217 -327 -396 -417 -44 -22
-113 -47 -201 -72 l-23 -6 0 881 0 881 23 -6 c12 -4 51 -15 86 -26z m-429
-2949 l0 -890 -250 0 -250 0 0 890 0 890 250 0 250 0 0 -890z m537 874 c328
-68 555 -284 665 -634 20 -66 23 -95 23 -240 0 -145 -3 -174 -23 -240 -77
-244 -205 -417 -392 -530 -121 -73 -296 -120 -447 -120 l-43 0 0 890 0 890 70
0 c39 0 105 -7 147 -16z"
        />
      </g>
    </svg>
  );
};

export default ThbIcon;
