const EPUBIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 36 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.57141 0H24.4195L36 12.8665V43.0221C36 44.6067 34.8481 45.8902 33.4286 45.8902H2.57141C1.15194 45.8902 0 44.6067 0 43.0221V2.86811C0 1.28352 1.15207 0 2.57141 0Z"
      fill="#FCD34D"
    />
    <path
      d="M4.02539 27.8926C4.02539 28.6133 4.41797 29 5.15039 29H8.74805C9.22266 29 9.53906 28.7012 9.53906 28.2324C9.53906 27.7695 9.22266 27.4648 8.75391 27.4648H5.94141V25.6836H8.63672C9.05273 25.6836 9.33398 25.4082 9.33398 24.9805C9.33398 24.5586 9.05273 24.2656 8.63672 24.2656H5.94141V22.5371H8.77734C9.22266 22.5371 9.53906 22.2266 9.53906 21.7754C9.53906 21.3184 9.22266 21.002 8.77734 21.002H5.15039C4.41797 21.002 4.02539 21.3945 4.02539 22.1094V27.8926ZM11.7012 29.0586C12.3223 29.0586 12.6738 28.6777 12.6738 28.0156V26.4453H13.9629C15.7969 26.4453 16.9219 25.4668 16.9219 23.7148C16.9219 22.0684 15.8145 21.002 14.0625 21.002H12.0059C11.0918 21.002 10.7402 21.3711 10.7402 22.3262V28.0156C10.7402 28.6836 11.0859 29.0586 11.7012 29.0586ZM12.6797 25.0039V22.4961H13.7754C14.5664 22.4961 15.0059 22.9531 15.0059 23.75C15.0059 24.6758 14.5605 25.0039 13.5586 25.0039H12.6797ZM24.5566 26.2578V21.9629C24.5566 21.3301 24.1875 20.9434 23.584 20.9434C22.9746 20.9434 22.6172 21.3301 22.6172 21.9629V26.0059C22.6172 26.9609 22.0781 27.5352 21.1992 27.5352C20.3086 27.5352 19.7812 26.9727 19.7812 26.0059V21.9629C19.7812 21.3301 19.418 20.9434 18.8145 20.9434C18.2051 20.9434 17.8418 21.3301 17.8418 21.9629V26.252C17.8418 28.0273 19.1191 29.1758 21.1992 29.1758C23.2441 29.1758 24.5566 28.0332 24.5566 26.2578ZM26.0215 27.8926C26.0215 28.6133 26.4141 29 27.1465 29H29.625C31.2949 29 32.4082 28.0918 32.4082 26.7324C32.4082 25.6543 31.6699 24.8691 30.5977 24.7988V24.7109C31.4766 24.5469 32.0273 23.8789 32.0273 22.9648C32.0273 21.7578 31.0781 21.002 29.5664 21.002H27.1465C26.4141 21.002 26.0215 21.3945 26.0215 22.1094V27.8926ZM27.9375 24.2539V22.373H29.0742C29.748 22.373 30.1348 22.7363 30.1348 23.2988C30.1348 23.8672 29.7539 24.2539 28.8691 24.2539H27.9375ZM27.9375 27.6289V25.4961H29.168C30.0117 25.4961 30.4395 25.9238 30.4395 26.5742C30.4395 27.2246 30.0117 27.6289 28.9746 27.6289H27.9375Z"
      fill="white"
    />
    <path
      d="M35.958 12.9116H26.9952C25.5758 12.9116 24.4238 11.6268 24.4238 10.0435V0.0336914L35.958 12.9116Z"
      fill="#F59E0B"
    />
  </svg>
);

export default EPUBIcon;
