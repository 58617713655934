const RonIcon = ({
  width = "100%",
  height = "100%",
  className = "",
}: {
  width?: string;
  height?: string;
  className?: string;
}) => {
  const regex = /\btext-([a-zA-Z0-9-]+)\b/;
  const matches = className.match(regex);
  const textClass = matches ? matches[0] : "text-white";
  return (
    <svg
      className={className + " rounded-full fill-current"}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
    >
      <g
        transform="translate(8,24.000000) scale(0.003000, -0.003000)"
        fill={textClass}
        stroke="none"
      >
        <path
          d="M25 4755 l-25 -24 0 -2141 0 -2141 25 -24 24 -25 299 0 299 0 24 26
24 26 0 2138 0 2138 -24 26 -24 26 -299 0 -299 0 -24 -25z"
        />
        <path
          d="M4570 4621 c-78 -25 -108 -43 -162 -94 -52 -51 -96 -115 -109 -161
-51 -175 -14 -341 102 -455 189 -188 515 -149 658 79 49 77 64 140 59 248 -3
82 -8 107 -34 163 -33 69 -111 157 -166 185 -108 56 -242 69 -348 35z"
        />
        <path
          d="M2370 3495 c-332 -57 -625 -227 -827 -482 -170 -213 -265 -424 -330
-732 -26 -119 -27 -140 -28 -401 0 -249 2 -285 23 -380 70 -323 184 -544 385
-748 160 -162 316 -258 537 -330 214 -70 348 -87 643 -79 326 8 585 56 817
150 100 40 130 68 130 121 0 56 -86 385 -109 416 -30 42 -67 45 -151 15 -249
-89 -376 -110 -670 -109 -275 0 -327 10 -495 93 -155 76 -273 196 -340 344
-35 77 -74 220 -81 298 l-7 69 972 0 c666 0 979 3 992 11 29 15 37 45 49 188
8 96 8 167 0 268 -34 414 -156 721 -383 959 -173 182 -367 283 -631 329 -108
18 -386 18 -496 0z m343 -549 c212 -41 358 -177 437 -408 15 -46 31 -107 35
-134 3 -27 8 -64 11 -81 l5 -33 -660 0 -660 0 25 92 c111 414 428 636 807 564z"
        />
        <path
          d="M4376 3409 l-26 -31 0 -1458 0 -1458 26 -31 26 -31 299 0 300 0 24
25 25 24 0 1471 0 1471 -25 24 -24 25 -300 0 -299 0 -26 -31z"
        />
      </g>
    </svg>
  );
};

export default RonIcon;
