const BgnIcon = ({
  width = "100%",
  height = "100%",
  className = "",
}: {
  width?: string;
  height?: string;
  className?: string;
}) => {
  return (
    <svg
      className={className + " rounded-full fill-current"}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
    >
      <path d="M7.889 20.727v-1.606l.268-.023c.344-.028.623-.159.835-.393.213-.237.371-.614.476-1.13.107-.52.177-1.22.207-2.097l.157-4.205h6.417v9.454h-1.943v-7.852h-2.618l-.148 3.291c-.049 1.059-.175 1.924-.378 2.595-.2.67-.52 1.166-.96 1.486-.437.32-1.036.48-1.796.48h-.517Zm9.971 0v-7.09h3.024c.874 0 1.565.166 2.073.498.508.332.762.805.762 1.417 0 .385-.143.705-.43.96-.286.256-.683.428-1.19.517.424.031.786.128 1.084.291.302.16.531.368.688.624.16.255.24.538.24.849 0 .403-.108.75-.323 1.039-.212.289-.525.51-.937.665-.41.153-.91.23-1.5.23h-3.49Zm1.833-1.472h1.657c.274 0 .488-.065.642-.194a.67.67 0 0 0 .236-.54c0-.253-.079-.451-.236-.596-.154-.145-.368-.217-.642-.217h-1.657v1.547Zm0-2.701h1.233c.197 0 .365-.028.503-.083a.705.705 0 0 0 .323-.25.641.641 0 0 0 .116-.383.592.592 0 0 0-.264-.512c-.175-.123-.415-.185-.72-.185h-1.19v1.413Z" />
    </svg>
  );
};

export default BgnIcon;
