const AIIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 36 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.57141 0H24.4195L36 12.8665V43.0221C36 44.6067 34.8481 45.8902 33.4286 45.8902H2.57141C1.15194 45.8902 0 44.6067 0 43.0221V2.86811C0 1.28352 1.15207 0 2.57141 0Z"
      fill="#E2574C"
    />
    <path
      d="M12.5879 29.0645C13.1035 29.0645 13.3789 28.8008 13.5723 28.1211L13.8828 27.166H16.5723L16.877 28.1211C17.0703 28.7832 17.3691 29.0645 17.8848 29.0645C18.4414 29.0645 18.8047 28.7363 18.8047 28.2266C18.8047 28.0215 18.752 27.7871 18.6465 27.459L16.8828 22.2734C16.5605 21.3184 16.1152 20.9434 15.2715 20.9434C14.4277 20.9434 13.9707 21.3242 13.6543 22.2734L11.8848 27.459C11.75 27.8516 11.6973 28.0742 11.6973 28.2676C11.6973 28.7363 12.0605 29.0645 12.5879 29.0645ZM14.2812 25.7656L15.1895 22.7363H15.2832L16.1797 25.7656H14.2812ZM20.8203 29.0586C21.4355 29.0586 21.7812 28.6836 21.7812 28.0156V21.9863C21.7812 21.3242 21.4297 20.9434 20.8086 20.9434C20.1875 20.9434 19.8418 21.3184 19.8418 21.9863V28.0156C19.8418 28.6777 20.1992 29.0586 20.8203 29.0586Z"
      fill="white"
    />
    <path
      d="M35.958 12.9116H26.9952C25.5758 12.9116 24.4238 11.6268 24.4238 10.0435V0.0336914L35.958 12.9116Z"
      fill="#B53629"
    />
  </svg>
);

export default AIIcon;
