const KesIcon = ({
  width = "100%",
  height = "100%",
  className = "",
}: {
  width?: string;
  height?: string;
  className?: string;
}) => {
  return (
    <svg
      className={className + " rounded-full fill-current"}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
    >
      <path d="M4.036 20.725V11.27h2v4.17h.124l3.402-4.17h2.396L8.45 15.505l3.55 5.22H9.608l-2.59-3.887-.983 1.2v2.687H4.036Zm14.016-6.735a1.168 1.168 0 0 0-.475-.868 1.887 1.887 0 0 0-1.14-.31 2.21 2.21 0 0 0-.827.137 1.162 1.162 0 0 0-.514.376.888.888 0 0 0-.178.545c-.01.155.027.31.108.443.084.131.194.242.326.325.15.097.311.176.48.236.19.068.384.125.581.168l.85.203c.39.086.77.21 1.135.37.326.142.628.333.896.568.25.22.45.491.589.794.145.33.218.687.215 1.047a2.62 2.62 0 0 1-.442 1.503 2.806 2.806 0 0 1-1.256.983 5.028 5.028 0 0 1-1.973.349 5.178 5.178 0 0 1-1.992-.35 2.895 2.895 0 0 1-1.32-1.042 3.086 3.086 0 0 1-.497-1.71h1.935c.01.285.106.56.274.791.169.216.394.381.652.476.296.11.61.164.925.16.302.005.601-.045.884-.148.228-.083.43-.225.584-.411a.947.947 0 0 0 .208-.605.79.79 0 0 0-.187-.536 1.455 1.455 0 0 0-.544-.369 5.644 5.644 0 0 0-.875-.277l-1.03-.258a4.313 4.313 0 0 1-1.888-.91 2.108 2.108 0 0 1-.688-1.666 2.47 2.47 0 0 1 .46-1.501 3.063 3.063 0 0 1 1.276-1.002 4.513 4.513 0 0 1 1.846-.36 4.38 4.38 0 0 1 1.84.36c.493.215.918.561 1.226 1.002.296.44.453.957.452 1.487h-1.916Zm5.313 2.635v4.1H21.4V11.27h1.912v3.615h.083a2.02 2.02 0 0 1 .775-.986 2.37 2.37 0 0 1 1.344-.357c.45-.01.896.1 1.29.32.37.217.668.536.858.92.215.444.319.934.303 1.428v4.515h-1.967v-4.164a1.44 1.44 0 0 0-.33-1.02 1.206 1.206 0 0 0-.935-.365 1.437 1.437 0 0 0-.709.17 1.2 1.2 0 0 0-.48.497 1.694 1.694 0 0 0-.178.782Z" />
    </svg>
  );
};

export default KesIcon;
