const IdrIcon = ({
  width = "100%",
  height = "100%",
  className = "",
}: {
  width?: string;
  height?: string;
  className?: string;
}) => {
  return (
    <svg
      className={className + " rounded-full fill-current"}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
    >
      <path d="M13.833 15.686c.488-.218.897-.58 1.175-1.036a3.088 3.088 0 0 0 .406-1.619 3.216 3.216 0 0 0-.4-1.631 2.689 2.689 0 0 0-1.159-1.076 4.043 4.043 0 0 0-1.83-.38h-3.73v9.454h1.999v-3.352h1.46l1.79 3.352h2.207l-2.01-3.682c.028-.013.064-.017.092-.03Zm-3.539-4.109h1.349c.327-.01.653.047.958.169.237.098.436.27.567.492.132.243.197.517.188.793.01.273-.056.542-.188.78-.131.216-.33.381-.565.471a2.487 2.487 0 0 1-.952.158h-1.357v-2.863Zm13.019 2.256a2.818 2.818 0 0 0-1.048-1.215 2.676 2.676 0 0 0-1.431-.403 2.205 2.205 0 0 0-.995.2c-.248.12-.468.29-.647.499a2.62 2.62 0 0 0-.376.584h-.087v-1.191H16.79v9.75h1.966v-3.795h.06c.1.213.23.41.388.584.183.202.405.365.653.478.306.134.638.199.972.189a2.657 2.657 0 0 0 1.461-.418 2.845 2.845 0 0 0 1.034-1.232 4.707 4.707 0 0 0 .381-2.001 4.64 4.64 0 0 0-.392-2.03Zm-1.79 3.111a1.638 1.638 0 0 1-.504.739 1.38 1.38 0 0 1-1.62.004 1.617 1.617 0 0 1-.508-.731 3.15 3.15 0 0 1-.176-1.104 3.107 3.107 0 0 1 .176-1.098 1.58 1.58 0 0 1 .505-.72c.233-.174.519-.264.81-.255.295-.009.584.084.817.264.233.191.407.444.503.73a3.507 3.507 0 0 1-.003 2.171Z" />
    </svg>
  );
};

export default IdrIcon;
