const PhpIcon = ({
  width = "100%",
  height = "100%",
  className = "",
}: {
  width?: string;
  height?: string;
  className?: string;
}) => {
  return (
    <svg
      className={className + " rounded-full fill-current"}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
    >
      <path d="M22.491 11.426h-1.775a3.784 3.784 0 0 0-1.776-2.091 5.635 5.635 0 0 0-2.784-.608H11.3v2.699H9.51v1.563h1.79v.767H9.51v1.562h1.79v7.955h1.76v-5.256h3.126c.96.04 1.914-.177 2.762-.628a3.89 3.89 0 0 0 1.584-1.691c.06-.123.107-.252.156-.38h1.804v-1.562h-1.47c.01-.141.022-.281.022-.427 0-.116-.011-.227-.017-.34h1.465v-1.563ZM13.06 10.29h3.096a3.818 3.818 0 0 1 1.868.387c.316.183.583.44.778.749H13.06V10.29Zm4.975 5.756c-.57.299-1.208.44-1.85.408H13.06v-1.136h5.715a2.326 2.326 0 0 1-.74.728Zm1.275-2.717c0 .15-.025.284-.037.427H13.06v-.768h6.22c.008.116.03.22.03.341Z" />
    </svg>
  );
};

export default PhpIcon;
