import React, { ReactNode } from "react";
import cx from "classnames";

export type ButtonTypes =
  | "primary"
  | "primaryProfile"
  | "secondary"
  | "secondaryProfile"
  | "square"
  | "danger"
  | "dangerContrast"
  | "settingOffer"
  | "success"
  | "info"
  | "none"
  | "waiting"
  | "rounded"
  | "sold"
  | "card"
  | "edit"
  | "kick"
  | "noTickets"
  | "roundedBlack"
  | "landing"
  | "follow"
  | "promotion"
  | "export"
  | "card";

interface Props {
  submit?: boolean;
  type?: ButtonTypes;
  children?: ReactNode;
  disabled?: boolean;
  loading?: boolean;
  fluid?: boolean;
  icon?: ReactNode;
  iconAfter?: boolean;
  className?: string;
  dataTestid?;
  onClick?(e: any): void;
}

function Button({
  type = "primary",
  submit = false,
  children,
  onClick,
  disabled = false,
  loading = false,
  fluid = false,
  icon,
  iconAfter = false,
  className = "",
  dataTestid,
}: Props) {
  const styledCx = getStyledCx(type, fluid);

  return (
    <button
      data-testid={dataTestid ? dataTestid : null}
      onClick={onClick}
      type={submit ? "submit" : "button"}
      disabled={disabled || loading}
      className={cx(styledCx, className)}
    >
      {loading ? (
        <span className="flex animate-spin mr-3">
          <span
            className="rounded-full border-4 border-b-4 h-4 w-4"
            style={{ borderColor: "black", borderBottomColor: "white" }}
          />
        </span>
      ) : icon ? (
        children ? (
          <span className={iconAfter ? " order-1 ml-2" : "mr-2"}>{icon}</span>
        ) : (
          icon
        )
      ) : null}
      {children}
    </button>
  );
}

// eslint-disable-next-line no-undef
const getStyledCx = (type: keyof typeof types, fluid: boolean) => {
  const types = {
    primary: `
      bg-slate-900 text-white hover:bg-slate-800
      items-center border border-transparent rounded-lg shadow-sm 
      py-2 px-4 inline-flex font-medium
      focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 
      disabled:opacity-75 disabled:pointer-events-none truncate 
    `,
    primaryProfile: `
      bg-slate-900 text-white hover:bg-slate-800
      items-center border border-transparent rounded-lg shadow-sm
      py-2 px-4 inline-flex font-medium
      focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400
      disabled:opacity-75 disabled:pointer-events-none truncate
    `,
    square: `
      bg-white border-gray-50
      items-center border border-transparent rounded-md shadow-sm
      py-2 px-2 inline-flex justify-center text-sm font-medium
      focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400
      disabled:opacity-75 disabled:pointer-events-none
    `,
    secondary: `
      bg-slate-50 hover:bg-slate-100 text-slate-900
      items-center border border-slate-500 rounded-lg shadow-sm
      py-2 px-4 inline-flex justify-center font-medium
      focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400
      disabled:opacity-75 disabled:pointer-events-none rounded-full
    `,
    edit: `
    bg-slate-50 hover:bg-slate-100 text-slate-500
    items-center border border-slate-500 rounded-md shadow-sm
    h-[32px] px-2 inline-flex justify-center font-medium
    focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400
    disabled:opacity-75 disabled:pointer-events-none rounded-full
  `,
    kick: `
  bg-slate-50 hover:bg-red-100 text-red-500
  items-center border border-red-500 rounded-md shadow-sm
  h-[32px] px-2 inline-flex justify-center font-medium
  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400
  disabled:opacity-75 disabled:pointer-events-none rounded-full
`,
    secondaryProfile: `
      bg-slate-50 hover:bg-slate-100 text-slate-900
      items-center border border-slate-200 rounded-lg shadow-sm
      py-2 px-4 inline-flex justify-center font-medium
      focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400
      disabled:opacity-75 disabled:pointer-events-none rounded-full
    `,
    rounded: `bg-slate-50 hover:bg-slate-100 text-slate-900
      items-center border border-slate-500 shadow-sm
      py-2.5 px-2.5 inline-flex justify-center font-medium
      focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400
      disabled:opacity-75 disabled:pointer-events-none rounded-full`,

    roundedBlack: `bg-slate-black h-[42px] w-[42px] text-slate-900
      items-center border border-slate-500 shadow-sm
      py-2.5 px-2.5 inline-flex justify-center font-medium
      focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400
      disabled:opacity-75 disabled:pointer-events-none rounded-full`,
    danger: `
      bg-landing-red hover:bg-hover-landing-red text-white
      items-center border border-transparent rounded-md shadow-sm
      py-2 px-4 inline-flex justify-center text-sm font-medium
      focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-hover-landing-red
      disabled:opacity-75 disabled:pointer-events-none
    `,
    settingOffer: `
      bg-slate-50 hover:bg-slate-100 text-slate-900
      items-center border border-transparent rounded-md shadow-sm
      py-2 px-4 inline-flex justify-center text-sm font-medium
      focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-gray-400
      disabled:opacity-75 disabled:pointer-events-none
    `,
    dangerContrast: `
      bg-ruslan-red hover:bg-red-600 text-white
      items-center border border-transparent rounded-md shadow-sm
      py-2 px-4 inline-flex justify-center text-sm font-medium
      focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-black focus:ring-red-400
      disabled:opacity-75 disabled:pointer-events-none
    `,
    success: `
      bg-custom-secondary-green hover:bg-green-600 text-white
      items-center border border-transparent rounded-md shadow-sm
      py-2 px-4 inline-flex justify-center text-sm font-medium
      focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-400
      disabled:opacity-75 disabled:pointer-events-none
    `,
    info: `
      bg-blue-500 hover:bg-blue-600 text-white
      items-center border border-transparent rounded-md shadow-sm
      py-2 px-4 inline-flex justify-center text-sm font-medium
      focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800
      disabled:opacity-75 disabled:pointer-events-none
    `,
    none: `
      hover:text-gray-300 items-center border border-transparent py-2 px-6 rounded-md
      inline-flex justify-center text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2
      focus:ring-offset-black focus:ring-gray-400 disabled:opacity-75 disabled:pointer-events-none
    `,
    export: `
    items-center border border-transparent py-2 px-6 rounded-md
    inline-flex justify-center text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2
    focus:ring-offset-gray-500 focus:ring-gray-400 disabled:opacity-75 disabled:pointer-events-none
  `,
    follow: `
      hover:text-gray-300 items-center border border-transparent py-2 px-6 rounded-full md:rounded-md
      inline-flex justify-center text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2
      focus:ring-offset-black focus:ring-gray-400 disabled:opacity-75 disabled:pointer-events-none
    `,
    landing: `
    font-poppins text-base font-medium text-white items-center px-4 h-[40px] rounded-md
    inline-flex justify-center focus:outline-none 
      disabled:opacity-75 disabled:pointer-events-none
  `,
    waiting: `
      bg-custom-yellow hover:bg-yellow-300 text-slate-900
      items-center rounded-lg shadow-sm
      py-2 px-4 inline-flex justify-center font-medium
      focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400
      disabled:opacity-75 disabled:pointer-events-none
    `,
    sold: `
      bg-slate-100 hover:bg-slate-200 text-slate-900
      items-center border border-slate-100 rounded-lg shadow-sm
      py-2 px-4 inline-flex justify-center font-medium
      focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400
      disabled:opacity-75 disabled:pointer-events-none rounded-full
    `,
    noTickets: `
    bg-slate-100 text-slate-900
    items-center border border-slate-100 rounded-lg shadow-sm
    py-2 px-4 inline-flex justify-center font-semibold
    focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400
    disabled:opacity-75 disabled:pointer-events-none rounded-full
  `,
    copy: `
      bg-slate-50 hover:bg-slate-100 text-slate-900 clipboard
      items-center border border-slate-500 rounded-lg shadow-sm
      py-2 px-4 inline-flex justify-center font-medium
      focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400
      disabled:opacity-75 disabled:pointer-events-none rounded-full
    `,
    card: `
    bg-slate-50 hover:bg-slate-100 text-slate-500 clipboard
    items-center border border-slate-500 rounded-lg shadow-sm
    py-2 px-4 inline-flex justify-center font-medium
    focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400
    disabled:opacity-75 disabled:pointer-events-none rounded-full
  `,
    promotion: `
  items-center border border-transparent border-custom-dark-blue rounded-lg shadow-sm 
  py-2 px-4 inline-flex font-medium
  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-custom-dark-blue
  disabled:opacity-75 disabled:pointer-events-none truncate 
`,
  };

  return cx(types[type], fluid && "w-full justify-center");
};

export default Button;
