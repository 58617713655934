const IlsIcon = ({
  width = "100%",
  height = "100%",
  className = "",
}: {
  width?: string;
  height?: string;
  className?: string;
}) => {
  const regex = /\btext-([a-zA-Z0-9-]+)\b/;
  const matches = className.match(regex);
  const textClass = matches ? matches[0] : "text-white";
  return (
    <svg
      className={className + " rounded-full fill-current"}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
    >
      <g
        transform="translate(8,24.000000) scale(0.003000, -0.003000)"
        fill={textClass}
        stroke="none"
      >
        <path
          d="M192 4621 c-72 -25 -145 -97 -172 -169 -20 -52 -20 -81 -20 -1900 l0
-1847 24 -50 c29 -63 111 -138 173 -159 137 -47 300 22 362 153 l26 56 3 1673
2 1673 828 -4 c887 -4 875 -3 1016 -56 331 -124 552 -365 649 -709 21 -76 21
-92 27 -942 l5 -865 25 -48 c57 -112 165 -174 289 -165 79 6 131 31 187 90 86
89 79 6 82 968 2 739 0 869 -13 955 -33 206 -72 324 -171 514 -80 154 -141
238 -264 365 -205 212 -438 350 -725 431 -171 49 -158 48 -1250 51 -985 4
-1032 3 -1083 -15z"
        />
        <path
          d="M4727 4620 c-73 -28 -136 -85 -166 -149 l-26 -56 -3 -1673 -2 -1673
-828 4 c-726 3 -835 5 -896 20 -345 83 -612 315 -732 636 -63 168 -64 177 -64
1070 0 920 4 872 -81 965 -66 73 -134 100 -234 94 -84 -6 -133 -29 -191 -90
-86 -89 -79 -6 -82 -968 -2 -739 0 -869 13 -955 33 -206 72 -324 171 -514 80
-154 141 -238 264 -365 242 -250 496 -386 870 -464 64 -13 219 -16 1098 -19
1166 -4 1091 -9 1186 78 40 36 61 65 75 105 21 54 21 63 21 1902 l0 1847 -24
50 c-29 63 -111 138 -173 159 -61 21 -137 20 -196 -4z"
        />
      </g>
    </svg>
  );
};

export default IlsIcon;
