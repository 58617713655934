const MP3Icon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 36 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.57141 0H24.4195L36 12.8665V43.0221C36 44.6067 34.8481 45.8902 33.4286 45.8902H2.57141C1.15194 45.8902 0 44.6067 0 43.0221V2.86811C0 1.28352 1.15207 0 2.57141 0Z"
      fill="#A78BFA"
    />
    <path
      d="M7.6875 29.0586C8.25 29.0586 8.57227 28.6895 8.57227 28.0508V24.0254H8.61914L10.0195 27.5879C10.248 28.1738 10.5234 28.373 10.9219 28.373C11.3145 28.373 11.584 28.1855 11.8125 27.5879L13.207 24.0254H13.2598V28.0508C13.2598 28.707 13.5527 29.0586 14.1035 29.0586C14.666 29.0586 14.9883 28.6895 14.9883 28.0508V22.1914C14.9883 21.3945 14.5723 20.9375 13.8457 20.9375C13.1953 20.9375 12.8145 21.2305 12.498 22.0801L10.9512 26.082H10.8867L9.33398 22.0859C9.02344 21.248 8.61914 20.9375 7.99805 20.9375C7.2832 20.9375 6.83789 21.4121 6.83789 22.1914V28.0508C6.83789 28.707 7.13086 29.0586 7.6875 29.0586ZM17.502 29.0586C18.123 29.0586 18.4746 28.6777 18.4746 28.0156V26.4453H19.7637C21.5977 26.4453 22.7227 25.4668 22.7227 23.7148C22.7227 22.0684 21.6152 21.002 19.8633 21.002H17.8066C16.8926 21.002 16.541 21.3711 16.541 22.3262V28.0156C16.541 28.6836 16.8867 29.0586 17.502 29.0586ZM18.4805 25.0039V22.4961H19.5762C20.3672 22.4961 20.8066 22.9531 20.8066 23.75C20.8066 24.6758 20.3613 25.0039 19.3594 25.0039H18.4805ZM23.5371 27.2891C23.5371 28.291 24.8145 29.1758 26.4434 29.1758C28.2891 29.1758 29.5957 28.1738 29.5957 26.7617C29.5957 25.7422 28.875 24.9277 27.9141 24.8574V24.7285C28.7227 24.6289 29.3613 23.8613 29.3613 23C29.3613 21.7402 28.166 20.8555 26.4551 20.8555C24.873 20.8555 23.625 21.7051 23.625 22.6719C23.625 23.1055 23.9473 23.4102 24.3984 23.4102C24.7031 23.4102 24.9492 23.2871 25.1602 23.0117C25.5176 22.5371 25.916 22.3145 26.4199 22.3145C27.0762 22.3145 27.5332 22.6895 27.5332 23.2344C27.5332 23.7793 27.0645 24.1777 26.4316 24.1777H25.9746C25.5645 24.1777 25.2715 24.4941 25.2715 24.9043C25.2715 25.332 25.5703 25.6426 25.9746 25.6426H26.4492C27.2109 25.6426 27.7207 26.0645 27.7207 26.6855C27.7207 27.3125 27.2168 27.7168 26.4434 27.7168C25.875 27.7168 25.4121 27.4707 25.0547 26.9902C24.791 26.6621 24.5566 26.5273 24.2754 26.5273C23.8477 26.5273 23.5371 26.8438 23.5371 27.2891Z"
      fill="white"
    />
    <path
      d="M35.958 12.9116H26.9952C25.5758 12.9116 24.4238 11.6268 24.4238 10.0435V0.0336914L35.958 12.9116Z"
      fill="#7C3AED"
    />
  </svg>
);

export default MP3Icon;
