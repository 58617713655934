const PlnIcon = ({
  width = "100%",
  height = "100%",
  className = "",
}: {
  width?: string;
  height?: string;
  className?: string;
}) => {
  return (
    <svg
      className={className + " rounded-full fill-current"}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
    >
      <path d="m12.827 19.824 4.011-5.16v-1.573h-7.04v1.932h4.125v.062l-4.272 5.29v1.443h7.335v-1.932h-4.159v-.062Zm9.523-5.58-1.086.348v-4.41h-2.42v5.186l-1.086.348v1.341l1.085-.348v5.109h2.421v-5.885l1.085-.348v-1.341Z" />
    </svg>
  );
};

export default PlnIcon;
