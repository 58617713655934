export const languagesOptions = [
  {
    value: "en",
    label: "English",
  },
  {
    value: "af",
    label: "Afrikaans",
  },
  {
    value: "sq",
    label: "Albanian - shqip",
  },
  {
    value: "am",
    label: "Amharic - አማርኛ",
  },
  {
    value: "ar",
    label: "Arabic - العربية",
  },
  {
    value: "an",
    label: "Aragonese - aragonés",
  },
  {
    value: "hy",
    label: "Armenian - հայերեն",
  },
  {
    value: "ast",
    label: "Asturian - asturianu",
  },
  {
    value: "az",
    label: "Azerbaijani - azərbaycandili",
  },
  {
    value: "eu",
    label: "Basque - euskara",
  },
  {
    value: "be",
    label: "Belarusian - беларуская",
  },
  {
    value: "bn",
    label: "Bengali - বাংলা",
  },
  {
    value: "bs",
    label: "Bosnian - bosanski",
  },
  {
    value: "br",
    label: "Breton - brezhoneg",
  },
  {
    value: "bg",
    label: "Bulgarian - български",
  },
  {
    value: "ca",
    label: "Catalan - català",
  },
  {
    value: "ckb",
    label: "Central Kurdish - کوردی (دەستنوسی عەرەبی)",
  },
  {
    value: "zh",
    label: "Chinese - 中文",
  },
  {
    value: "zh - HK",
    label: "Chinese (HongKong) - 中文（香港）",
  },
  {
    value: "zh - CN",
    label: "Chinese (Simplified) - 中文（简体）",
  },
  {
    value: "zh - TW",
    label: "Chinese (Traditional) - 中文（繁體）",
  },
  {
    value: "co",
    label: "Corsican",
  },
  {
    value: "hr",
    label: "Croatian - hrvatski",
  },
  {
    value: "cs",
    label: "Czech - čeština",
  },
  {
    value: "da",
    label: "Danish - dansk",
  },
  {
    value: "nl",
    label: "Dutch - Nederlands",
  },
  {
    value: "en - AU",
    label: "English (Australia)",
  },
  {
    value: "en - CA",
    label: "English (Canada)",
  },
  {
    value: "en - IN",
    label: "English (India)",
  },
  {
    value: "en - NZ",
    label: "English (New Zealand)",
  },
  {
    value: "en - ZA",
    label: "English (South Africa)",
  },
  {
    value: "en - GB",
    label: "English (United Kingdom)",
  },
  {
    value: "en - US",
    label: "English (United States)",
  },
  {
    value: "eo",
    label: "Esperanto - esperanto",
  },
  {
    value: "et",
    label: "Estonian - eesti",
  },
  {
    value: "fo",
    label: "Faroese - føroyskt",
  },
  {
    value: "fil",
    label: "Filipino",
  },
  {
    value: "fi",
    label: "Finnish - suomi",
  },
  {
    value: "fr",
    label: "French - français",
  },
  {
    value: "fr - CA",
    label: "French (Canada) - français (Canada)",
  },
  {
    value: "fr - FR",
    label: "French (France) - français (France)",
  },
  {
    value: "fr - CH",
    label: "French (Switzerland) - français (Suisse)",
  },
  {
    value: "gl",
    label: "Galician - galego",
  },
  {
    value: "ka",
    label: "Georgian - ქართული",
  },
  {
    value: "de",
    label: "German - Deutsch",
  },
  {
    value: "de - AT",
    label: "German (Austria) - Deutsch (Österreich)",
  },
  {
    value: "de - DE",
    label: "German (Germany) - Deutsch (Deutschland)",
  },
  {
    value: "de - LI",
    label: "German (Liechtenstein) - Deutsch (Liechtenstein)",
  },
  {
    value: "de - CH",
    label: "German (Switzerland) - Deutsch (Schweiz)",
  },
  {
    value: "el",
    label: "Greek - Ελληνικά",
  },
  {
    value: "gn",
    label: "Guarani",
  },
  {
    value: "gu",
    label: "Gujarati - ગુજરાતી",
  },
  {
    value: "ha",
    label: "Hausa",
  },
  {
    value: "haw",
    label: "Hawaiian - ʻŌlelo Hawaiʻi",
  },
  {
    value: "he",
    label: "Hebrew - עברית",
  },
  {
    value: "hi",
    label: "Hindi - हिन्दी",
  },
  {
    value: "hu",
    label: "Hungarian - magyar",
  },
  {
    value: "is",
    label: "Icelandic - íslenska",
  },
  {
    value: "id",
    label: "Indonesian - Indonesia",
  },
  {
    value: "ia",
    label: "Interlingua",
  },
  {
    value: "ga",
    label: "Irish - Gaeilge",
  },
  {
    value: "it",
    label: "Italian - italiano",
  },
  {
    value: "it - IT",
    label: "Italian (Italy) - italiano (Italia)",
  },
  {
    value: "it - CH",
    label: "Italian (Switzerland) - italiano (Svizzera)",
  },
  {
    value: "ja",
    label: "Japanese - 日本語",
  },
  {
    value: "kn",
    label: "Kannada - ಕನ್ನಡ",
  },
  {
    value: "kk",
    label: "Kazakh - қазақтілі",
  },
  {
    value: "km",
    label: "Khmer - ខ្មែរ",
  },
  {
    value: "ko",
    label: "Korean - 한국어",
  },
  {
    value: "ku",
    label: "Kurdish - Kurdî",
  },
  {
    value: "ky",
    label: "Kyrgyz - кыргызча",
  },
  {
    value: "lo",
    label: "Lao - ລາວ",
  },
  {
    value: "la",
    label: "Latin",
  },
  {
    value: "lv",
    label: "Latvian - latviešu",
  },
  {
    value: "ln",
    label: "Lingala - lingála",
  },
  {
    value: "lt",
    label: "Lithuanian - lietuvių",
  },
  {
    value: "mk",
    label: "Macedonian - македонски",
  },
  {
    value: "ms",
    label: "Malay - Bahasa Melayu",
  },
  {
    value: "ml",
    label: "Malayalam - മലയാളം",
  },
  {
    value: "mt",
    label: "Maltese - Malti",
  },
  {
    value: "mr",
    label: "Marathi - मराठी",
  },
  {
    value: "mn",
    label: "Mongolian - монгол",
  },
  {
    value: "ne",
    label: "Nepali - नेपाली",
  },
  {
    value: "no",
    label: "Norwegian - norsk",
  },
  {
    value: "nb",
    label: "Norwegian Bokmål - norskbokmål",
  },
  {
    value: "nn",
    label: "Norwegian Nynorsk - nynorsk",
  },
  {
    value: "oc",
    label: "Occitan",
  },
  {
    value: "or",
    label: "Oriya - ଓଡ଼ିଆ",
  },
  {
    value: "om",
    label: "Oromo - Oromoo",
  },
  {
    value: "ps",
    label: "Pashto - پښتو",
  },
  {
    value: "fa",
    label: "Persian - فارسی",
  },
  {
    value: "pl",
    label: "Polish - polski",
  },
  {
    value: "pt",
    label: "Portuguese - português",
  },
  {
    value: "pt - BR",
    label: "Portuguese (Brazil) - português (Brasil)",
  },
  {
    value: "pt - PT",
    label: "Portuguese (Portugal) - português (Portugal)",
  },
  {
    value: "pa",
    label: "Punjabi - ਪੰਜਾਬੀ",
  },
  {
    value: "qu",
    label: "Quechua",
  },
  {
    value: "ro",
    label: "Romanian - română",
  },
  {
    value: "mo",
    label: "Romanian (Moldova) - română (Moldova)",
  },
  {
    value: "rm",
    label: "Romansh - rumantsch",
  },
  {
    value: "ru",
    label: "Russian - русский",
  },
  {
    value: "gd",
    label: "Scottish Gaelic",
  },
  {
    value: "sr",
    label: "Serbian - српски",
  },
  {
    value: "sh",
    label: "Serbo - Croatian - Srpskohrvatski",
  },
  {
    value: "sn",
    label: "Shona - chiShona",
  },
  {
    value: "sd",
    label: "Sindhi",
  },
  {
    value: "si",
    label: "Sinhala - සිංහල",
  },
  {
    value: "sk",
    label: "Slovak - slovenčina",
  },
  {
    value: "sl",
    label: "Slovenian - slovenščina",
  },
  {
    value: "so",
    label: "Somali - Soomaali",
  },
  {
    value: "st",
    label: "Southern Sotho",
  },
  {
    value: "es",
    label: "Spanish - español",
  },
  {
    value: "es - AR",
    label: "Spanish (Argentina) - español (Argentina)",
  },
  {
    value: "es - 419",
    label: "Spanish (Latin America) - español (Latinoamérica)",
  },
  {
    value: "es - MX",
    label: "Spanish (Mexico) - español (México)",
  },
  {
    value: "es - ES",
    label: "Spanish (Spain) - español (España)",
  },
  {
    value: "es - US",
    label: "Spanish (United States) - español (Estados Unidos)",
  },
  {
    value: "su",
    label: "Sundanese",
  },
  {
    value: "sw",
    label: "Swahili - Kiswahili",
  },
  {
    value: "sv",
    label: "Swedish - svenska",
  },
  {
    value: "tg",
    label: "Tajik - тоҷикӣ",
  },
  {
    value: "ta",
    label: "Tamil - தமிழ்",
  },
  {
    value: "tt",
    label: "Tatar",
  },
  {
    value: "te",
    label: "Telugu - తెలుగు",
  },
  {
    value: "th",
    label: "Thai - ไทย",
  },
  {
    value: "ti",
    label: "Tigrinya - ትግርኛ",
  },
  {
    value: "to",
    label: "Tongan - leafakatonga",
  },
  {
    value: "tr",
    label: "Turkish - Türkçe",
  },
  {
    value: "tk",
    label: "Turkmen",
  },
  {
    value: "tw",
    label: "Twi",
  },
  {
    value: "uk",
    label: "Ukrainian - українська",
  },
  {
    value: "ua",
    label: "Ukrainian - українська",
  },
  {
    value: "ur",
    label: "Urdu - اردو",
  },
  {
    value: "ug",
    label: "Uyghur",
  },
  {
    value: "uz",
    label: "Uzbek - o‘zbek",
  },
  {
    value: "vi",
    label: "Vietnamese - Tiếng Việt",
  },
  {
    value: "wa",
    label: "Walloon - wa",
  },
  {
    value: "cy",
    label: "Welsh - Cymraeg",
  },
  {
    value: "fy",
    label: "Western Frisian",
  },
  {
    value: "xh",
    label: "Xhosa",
  },
  {
    value: "yi",
    label: "Yiddish",
  },
  {
    value: "yo",
    label: "Yoruba - Èdè Yorùbá",
  },
  {
    value: "zu",
    label: "Zulu - isiZulu",
  },
];
