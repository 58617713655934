const HryvniaIcon = ({
  width = "",
  height = "",
  color = "#64748b",
  className = "",
}: {
  width?: string;
  height?: string;
  color?: string;
  className?: string;
}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    style={{ width, height }}
    viewBox="0 0 16 16"
    fill="none"
  >
    <rect width="16" height="16" rx="8" fill={color} />
    <path
      d="M12.4 7.5999C12.621 7.5999 12.8 7.4209 12.8 7.1999V6.3999C12.8 6.1789 12.621 5.9999 12.4 5.9999H11.3535C11.6888 5.28415 11.697 4.41665 11.2503 3.64865C10.7835 2.8459 9.8775 2.3999 8.949 2.3999H6.9785C6.3705 2.3999 5.782 2.61315 5.315 3.00215L5.02075 3.2474C4.76625 3.45965 4.73175 3.8379 4.944 4.0924L5.45625 4.70715C5.6685 4.9619 6.04675 4.99615 6.3015 4.7839L6.59475 4.5394C6.70275 4.4494 6.839 4.40015 6.9795 4.40015H9.07C9.36225 4.40015 9.6 4.63815 9.6 4.93015C9.6 5.0779 9.538 5.21965 9.42975 5.31965L8.6925 5.9999H3.6C3.379 5.9999 3.2 6.1789 3.2 6.3999V7.1999C3.2 7.4209 3.379 7.5999 3.6 7.5999H6.95925L6.0925 8.3999H3.6C3.379 8.3999 3.2 8.5789 3.2 8.7999V9.5999C3.2 9.8209 3.379 9.9999 3.6 9.9999H4.6465C4.31125 10.7157 4.303 11.5832 4.74975 12.3512C5.2165 13.1537 6.1225 13.5999 7.051 13.5999H9.0215C9.6295 13.5999 10.218 13.3867 10.685 12.9977L10.9793 12.7524C11.2338 12.5402 11.2682 12.1619 11.056 11.9074L10.5438 11.2927C10.3315 11.0379 9.95325 11.0037 9.6985 11.2159L9.40475 11.4609C9.29695 11.5507 9.16107 11.5999 9.02075 11.5999H6.93C6.63775 11.5999 6.4 11.3619 6.4 11.0699C6.4 10.9222 6.462 10.7804 6.57025 10.6804L7.3075 9.9999H12.4C12.621 9.9999 12.8 9.8209 12.8 9.5999V8.7999C12.8 8.5789 12.621 8.3999 12.4 8.3999H9.04075L9.9075 7.5999H12.4Z"
      fill="white"
    />
  </svg>
);

export default HryvniaIcon;
