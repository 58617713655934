const KEYIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 36 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.57141 0H24.4195L36 12.8665V43.0221C36 44.6067 34.8481 45.8902 33.4286 45.8902H2.57141C1.15194 45.8902 0 44.6067 0 43.0221V2.86811C0 1.28352 1.15207 0 2.57141 0Z"
      fill="#E2574C"
    />
    <path
      d="M12.6934 29.0469C13.2441 29.0469 13.625 28.6719 13.625 28.1328C13.625 27.8633 13.5371 27.6641 13.2266 27.248L11.2227 24.541L13.1035 22.5078C13.3613 22.2266 13.4551 22.0449 13.4551 21.8047C13.4551 21.3301 13.0859 20.9551 12.6055 20.9551C12.3301 20.9551 12.1074 21.0781 11.8438 21.3652L9.1543 24.2891H9.04883V21.9863C9.04883 21.3242 8.69727 20.9434 8.07617 20.9434C7.45508 20.9434 7.10938 21.3184 7.10938 21.9863V28.0156C7.10938 28.6719 7.47266 29.0586 8.09961 29.0586C8.70898 29.0586 9.04883 28.6836 9.04883 28.0156V26.5156L9.78711 25.7656L11.7324 28.4434C12.0371 28.8652 12.3301 29.0469 12.6934 29.0469ZM14.75 27.8926C14.75 28.6133 15.1426 29 15.875 29H19.4727C19.9473 29 20.2637 28.7012 20.2637 28.2324C20.2637 27.7695 19.9473 27.4648 19.4785 27.4648H16.666V25.6836H19.3613C19.7773 25.6836 20.0586 25.4082 20.0586 24.9805C20.0586 24.5586 19.7773 24.2656 19.3613 24.2656H16.666V22.5371H19.502C19.9473 22.5371 20.2637 22.2266 20.2637 21.7754C20.2637 21.3184 19.9473 21.002 19.502 21.002H15.875C15.1426 21.002 14.75 21.3945 14.75 22.1094V27.8926ZM24.1777 29.0586C24.7637 29.0586 25.1562 28.6602 25.1387 28.0859L25.1035 25.9941L27.1367 22.625C27.3652 22.2441 27.459 22.0039 27.459 21.7695C27.459 21.2949 27.084 20.9316 26.5918 20.9316C26.2051 20.9316 25.8945 21.166 25.6484 21.6406L24.207 24.3125H24.1367L22.7363 21.6348C22.4785 21.1367 22.209 20.9316 21.793 20.9316C21.2598 20.9316 20.8965 21.2832 20.8965 21.8047C20.8965 22.0332 20.9785 22.2617 21.1777 22.5957L23.2402 25.9941L23.1992 28.0859C23.1816 28.6602 23.5801 29.0586 24.1777 29.0586Z"
      fill="white"
    />
    <path
      d="M35.958 12.9116H26.9952C25.5758 12.9116 24.4238 11.6268 24.4238 10.0435V0.0336914L35.958 12.9116Z"
      fill="#B53629"
    />
  </svg>
);

export default KEYIcon;
