const InrIcon = ({
  width = "100%",
  height = "100%",
  className = "",
}: {
  width?: string;
  height?: string;
  className?: string;
}) => {
  return (
    <svg
      className={className + " rounded-full fill-current"}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
    >
      <path d="M18.4 10.844a3.535 3.535 0 0 0-.242-.361l2.246.013.504-1.769H11.61l-.519 1.89h2.7a3.053 3.053 0 0 1 2.222.703c.253.253.442.562.553.901H11.59l-.498 1.755h5.54a2.207 2.207 0 0 1-.249.6 2.148 2.148 0 0 1-.994.849 3.917 3.917 0 0 1-1.598.291H11.27l.014 1.385 5.086 6.172h2.591v-.12l-4.644-5.69.064-.014a5.943 5.943 0 0 0 2.649-.69 3.622 3.622 0 0 0 1.47-1.526 4.43 4.43 0 0 0 .389-1.257h1.515l.497-1.755h-2.025a4.284 4.284 0 0 0-.475-1.377Z" />
    </svg>
  );
};

export default InrIcon;
