const AccessIcon = ({
  width = "",
  height = "",
  color = "#f8fafc",
  className = "",
}: {
  width?: string;
  height?: string;
  color?: string;
  className?: string;
}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    style={{ width, height }}
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clipPath="url(#clip0_28052_12046)">
      <path
        d="M10.9395 15.4403C10.7995 15.5794 10.6885 15.7448 10.6126 15.9269C10.5367 16.109 10.4975 16.3044 10.4972 16.5017C10.4969 16.699 10.5356 16.8944 10.611 17.0768C10.6864 17.2591 10.797 17.4248 10.9365 17.5643C11.076 17.7038 11.2417 17.8145 11.424 17.8898C11.6064 17.9652 11.8018 18.0039 11.9991 18.0036C12.1965 18.0033 12.3918 17.9641 12.5739 17.8882C12.756 17.8123 12.9214 17.7013 13.0605 17.5614L17.5606 13.0614C17.6999 12.9221 17.8104 12.7568 17.8858 12.5748C17.9612 12.3928 18 12.1978 18 12.0008C18 11.8038 17.9612 11.6088 17.8858 11.4268C17.8104 11.2448 17.6999 11.0795 17.5606 10.9403L13.0605 6.44026C12.779 6.16027 12.3979 6.00336 12.0009 6.00391C11.6038 6.00446 11.2232 6.16244 10.9424 6.4432C10.6616 6.72397 10.5037 7.10461 10.5031 7.50167C10.5025 7.89873 10.6595 8.27981 10.9395 8.56135L12.8789 10.5008H1.5C1.10218 10.5008 0.720644 10.6588 0.43934 10.9401C0.158035 11.2214 0 11.603 0 12.0008C0 12.3986 0.158035 12.7802 0.43934 13.0615C0.720644 13.3428 1.10218 13.5008 1.5 13.5008H12.8789L10.9395 15.4403Z"
        fill={color}
      />
      <path
        d="M22.5 2.41129e-07H7.5C7.30299 -0.000111495 7.10788 0.0386109 6.92584 0.113953C6.7438 0.189296 6.5784 0.299781 6.43909 0.439091C6.29978 0.578401 6.1893 0.743804 6.11395 0.925843C6.03861 1.10788 5.99989 1.30299 6 1.5V4.5C6 4.89783 6.15804 5.27936 6.43934 5.56066C6.72064 5.84197 7.10218 6 7.5 6C7.89782 6 8.27936 5.84197 8.56066 5.56066C8.84197 5.27936 9 4.89783 9 4.5V3H21V21H9V19.5C9 19.1022 8.84197 18.7206 8.56066 18.4393C8.27936 18.158 7.89782 18 7.5 18C7.10218 18 6.72064 18.158 6.43934 18.4393C6.15804 18.7206 6 19.1022 6 19.5V22.5C5.99989 22.697 6.03861 22.8921 6.11395 23.0742C6.1893 23.2562 6.29978 23.4216 6.43909 23.5609C6.5784 23.7002 6.7438 23.8107 6.92584 23.886C7.10788 23.9614 7.30299 24.0001 7.5 24H22.5C22.697 24.0001 22.8921 23.9614 23.0742 23.886C23.2562 23.8107 23.4216 23.7002 23.5609 23.5609C23.7002 23.4216 23.8107 23.2562 23.886 23.0742C23.9614 22.8921 24.0001 22.697 24 22.5V1.5C24.0001 1.30299 23.9614 1.10788 23.886 0.925843C23.8107 0.743804 23.7002 0.578401 23.5609 0.439091C23.4216 0.299781 23.2562 0.189296 23.0742 0.113953C22.8921 0.0386109 22.697 -0.000111495 22.5 2.41129e-07Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_28052_12046">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default AccessIcon;
